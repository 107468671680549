import { FetchApi } from '../../axios';


function getListRefundRequest(data) {
    return FetchApi('/v1.0/refund', 'get', data)
}
function getAllListRefundRequest(data) {
    return FetchApi('/v1.0/refund/list/get-list-all', 'get', data)
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createRefundRequest(data) {
    return FetchApi('/v1.0/refund', 'post', data);
}
function updateRefundRequest(data) {
    return FetchApi('/v1.0/refund', 'put', data)
}
function getRefundRequestById(data) {
    return FetchApi(`/v1.0/refund/${data}`, 'get')
}
function getHistoryTranferByUserId(data) {
    return FetchApi(`/v1.0/refund/get-by-user_id/get-list`, 'get', data)
}
function deleteRefundRequest(data) {
    return FetchApi('/v1.0/refund', 'delete', data)
}

export default {
    getListRefundRequest,
    getAllListRefundRequest,
    getHistoryTranferByUserId,
    createRefundRequest,
    updateRefundRequest,
    getRefundRequestById,
    deleteRefundRequest
}