import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
const RefundRoseRequest = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/User/ListRefundRoseRequest'))} />
        <Route exact path={`${match.url}/list/:refund_id`} component={asyncComponent(() => import('../../components/User/ListRefundRoseRequest'))} />

    </Switch>
)

export default RefundRoseRequest;