import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";

class CustomScrollbars extends Component {
    constructor(props, context) {
        super(props, context)
    }

    renderView({ style, ...props }) {
        // console.log("ccccccccccccccccccccccccc", style)
        let fixCss = { WebkitOverflowScrolling: "touch", bottom: 0, left: 0, marginBottom: -23, marginRight: -2, overflow: "scroll", position: "absolute", right: 0, top: 0 }
        return (
            <div {...props} style={{ ...fixCss }} />
        );
    }

    render() {
        return (
            <Scrollbars
                renderView={this.renderView}
                {...this.props} />
        );
    }
}

export default CustomScrollbars;
