import { FetchApi } from '../../axios';

function getListCategoryMove(data) {
    return FetchApi('/v1.0/mcategorymove', 'get', data)
}

function getAllListCategoryMove(data) {
    return FetchApi('/v1.0/mcategorymove/list/get-list-all', 'get', data)
}

function deleteCategoryMove(data) {
    return FetchApi('/v1.0/mcategorymove', 'delete', data)
}

function createCategoryMove(data) {
    return FetchApi('/v1.0/mcategorymove', 'post', data)
}

function updateCategoryMove(data) {
    return FetchApi('/v1.0/mcategorymove', 'put', data)
}

// function updateServiceAttraction(data) {
//     return FetchApi('/v1.0/services/service-attaction', 'put', data)
// }

function getCategoryMoveByID(data) {
    return FetchApi(`/v1.0/mcategorymove/${data}`, 'get')
}

//Service
function getListMService(data) {
    return FetchApi('/v1.0/mservice', 'get', data)
}
function deleteMService(data) {
    return FetchApi('/v1.0/mservice', 'delete', data)
}
function createMService(data) {
    return FetchApi('/v1.0/mservice', 'post', data)
}
function updateMService(data) {
    return FetchApi('/v1.0/mservice', 'put', data)
}
function updateServiceOfMove(data) {
    return FetchApi('/v1.0/mservice/service-of-move', 'put', data)
}
function getMServiceByID(data) {
    return FetchApi(`/v1.0/mservice/${data}`, 'get')
}

//Move
function getListMove(data) {
    return FetchApi('/v1.0/move', 'get', data)
}
function deleteMove(data) {
    return FetchApi('/v1.0/move', 'delete', data)
}
function createMove(data) {
    return FetchApi('/v1.0/move', 'post', data)
}
function updateMove(data) {
    return FetchApi('/v1.0/move', 'put', data)
}
function getMoveByID(data) {
    return FetchApi(`/v1.0/move/${data}`, 'get')
}
function geNametMoveByID(data) {
    return FetchApi(`/v1.0/move/${data}/get-name`, 'get')
}
function getListServiceOfMove(data) {
    return FetchApi('/v1.0/move/list/get-service-of-move', 'get', data)
}

//Move vé xe rẻ
function getListMoveCheapCarTicket(data) {
    return FetchApi('/v1.0/move/list-move/get-cheap-car-ticket', 'get', data)
}
function getDetailCheapCarTicket(data) {
    return FetchApi('/v1.0/move/detail-ticket/get-detail-cheap-car-ticket', 'get', data)
}
function getCountCheapCarTicketByMonth(data) {
    return FetchApi('/v1.0/move/statistical-ticket/get-count-cheap-car-ticket', 'get', data)
}

export default {
    getListCategoryMove,
    getAllListCategoryMove,
    geNametMoveByID,
    deleteCategoryMove,
    createCategoryMove,
    updateCategoryMove,
    getCategoryMoveByID,
    getListMService,
    deleteMService,
    createMService,
    updateMService,
    updateServiceOfMove,
    getMServiceByID,
    getListMove,
    deleteMove,
    createMove,
    updateMove,
    getMoveByID,
    getListServiceOfMove,
    getListMoveCheapCarTicket,
    getDetailCheapCarTicket,
    getCountCheapCarTicketByMonth
}