import React from "react";
import { Route, Switch } from "react-router-dom";
import { CheckPermission } from "util/CheckPermission";
import asyncComponent from "util/asyncComponent";
const User = ({ match }) => (
    <Switch>
        {CheckPermission("management_user") && <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/User/ListUser'))} />}
        {CheckPermission("management_user") && <Route exact path={`${match.url}/list_user_special`} component={asyncComponent(() => import('../../components/User/ListSpecialUser'))} />}
        {CheckPermission("management_user") && <Route exact path={`${match.url}/list/pending`} component={asyncComponent(() => import('../../components/User/ListUserPending'))} />}
        {CheckPermission("management_user") && <Route exact path={`${match.url}/list/:user_id`} component={asyncComponent(() => import('../../components/User/ListUser'))} />}
        {CheckPermission("management_user") && <Route exact path={`${match.url}/top`} component={asyncComponent(() => import('../../components/TopUser/index'))} />}
        {CheckPermission("management_user") && <Route exact path={`${match.url}/top/commission`} component={asyncComponent(() => import('../../components/TopUser/TopCommission'))} />}


    </Switch>
)

export default User;
