import { FetchApi } from '../../axios';

function getListRegion(data) {
    return FetchApi('/v1.0/regions', 'get', data)
}
function getAllRegion(data) {
    return FetchApi('/v1.0/regions/list/all', 'get', data)
}

function deleteRegion(data) {
    return FetchApi('/v1.0/regions', 'delete', data)
}

function createRegion(data) {
    return FetchApi('/v1.0/regions', 'post', data)
}

function updateRegion(data) {
    return FetchApi('/v1.0/regions', 'put', data)
}

function getRegionByID(data) {
    return FetchApi(`/v1.0/regions/${data}`, 'get')
}

function getRegionActive(data) {
    return FetchApi(`/v1.0/regions/active`, 'get', data)
}
export default {
    getListRegion,
    getAllRegion,
    deleteRegion,
    createRegion,
    updateRegion,
    getRegionByID,
    getRegionActive
}