import { FetchApi } from '../../axios';

function getListPost(data) {
    return FetchApi('/v1.0/posts', 'get', data)
}
function getListBlog(data) {
    return FetchApi('/v1.0/posts/getlist-blog/blog', 'get', data)
}
function getFivePost(data) {
    return FetchApi('/v1.0/posts/get-five-post/list', 'get', data)
}
function getAllPost(data) {
    return FetchApi('/v1.0/posts/list/all', 'get', data)
}

function deletePost(data) {
    return FetchApi('/v1.0/posts', 'delete', data)
}
function deleteBlog(data) {
    return FetchApi('/v1.0/posts/delete-blog', 'delete', data)
}


function createPost(data) {
    return FetchApi('/v1.0/posts', 'post', data)
}
function createBlog(data) {
    return FetchApi('/v1.0/posts/create-blog', 'post', data)
}

function updatePost(data) {
    return FetchApi('/v1.0/posts', 'put', data)
}
function updateBlog(data) {
    return FetchApi('/v1.0/posts/update-blog', 'put', data)
}
function updateStatusPost(data) {
    return FetchApi('/v1.0/posts/update-status/active_tin_tuc', 'put', data)
}
function updateStatusBlog(data) {
    return FetchApi('/v1.0/posts/update-status/active_blog', 'put', data)
}

function sendNotifyPost(data) {
    return FetchApi('/v1.0/posts/send-notify', 'put', data)
}

function getPostByID(data) {
    return FetchApi(`/v1.0/posts/${data}`, 'get')
}

//Comment
function getListComment(post_id, data) {
    return FetchApi(`/v1.0/posts/comment/${post_id}`, 'get', data)
}

// function createComment(data) {
//     return FetchApi('/v1.0/posts/comment', 'post', data)
// }

function updateComment(data) {
    return FetchApi('/v1.0/posts/comment', 'put', data)
}

function deleteComment(data) {
    return FetchApi('/v1.0/posts/comment', 'delete', data)
}

//Tag 
function getListTag() {
    return FetchApi('/v1.0/posts/tag/list_tag', 'get')
}

function getFacebookPageId(id) {
    return FetchApi(`/v1.0/posts/facebookid/${id}`, 'get')
}

function getFacebookProfileId(id) {
    return FetchApi(`/v1.0/posts/facebook-profile-id/${id}`, 'get')
}

export default {
    getListPost,
    getListBlog,
    getFivePost,
    getAllPost,
    deletePost,
    createPost,
    updatePost,
    updateStatusPost,
    updateStatusBlog,
    getPostByID,
    getListComment,
    createBlog,
    updateBlog,
    deleteBlog,
    // createComment,
    updateComment,
    deleteComment,
    getListTag,
    sendNotifyPost,
    getFacebookPageId,
    getFacebookProfileId
}