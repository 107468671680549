import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
// import { CheckPermission } from "util/CheckPermission";

const Move = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}/invoice/list`} component={asyncComponent(() => import('../../components/Order/ListInvoice'))} />
        <Route exact path={`${match.url}/ticket_order_booking/list`} component={asyncComponent(() => import('../../components/Order/ListTicketOrderBooking'))} />
        <Route exact path={`${match.url}/ticket_order_booking/list/:order_id`} component={asyncComponent(() => import('../../components/Order/ListTicketOrderBooking'))} />
    </Switch>
)

export default Move;
