import { FetchApi } from '../../axios';


function getListRefundRoseRequest(data) {
    return FetchApi('/v1.0/refund_rose_request', 'get', data)
}
function getAllListRefundRoseRequest(data) {
    return FetchApi('/v1.0/refund_rose_request/list/get-list-all', 'get', data)
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createRefundRoseRequest(data) {
    return FetchApi('/v1.0/refund_rose_request', 'post', data);
}
function updateRefundRoseRequest(data) {
    return FetchApi('/v1.0/refund_rose_request', 'put', data)
}
function getRefundRoseRequestById(data) {
    return FetchApi(`/v1.0/refund_rose_request/${data}`, 'get')
}
function getAllHistoryRefundRoseByUserId(data) {
    return FetchApi(`/v1.0/refund_rose_request/get-by-user_id/get-list`, 'get', data)
}
function deleteRefundRoseRequest(data) {
    return FetchApi('/v1.0/refund_rose_request', 'delete', data)
}

export default {
    getListRefundRoseRequest,
    getAllListRefundRoseRequest,
    getAllHistoryRefundRoseByUserId,
    createRefundRoseRequest,
    updateRefundRoseRequest,
    getRefundRoseRequestById,
    deleteRefundRoseRequest
}