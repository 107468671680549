import { FetchApi } from '../../axios';


function getListRating(data) {
    return FetchApi('/v1.0/ratings', 'get', data)
}
function getRatingById(data) {
    return FetchApi('/v1.0/ratings/get-info-ratings-by-id', 'get', data)
}
function deleteRating(data) {
    return FetchApi('/v1.0/ratings', 'delete', data)
}

export default{
    getListRating,
    getRatingById,
    deleteRating
}