import { FetchApi } from '../../axios';


function getListTicketGroup(data) {
    return FetchApi('/v1.0/ticket_group', 'get', data)
}
function getAllListTicketGroup(data) {
    return FetchApi('/v1.0/ticket_group/list/get-list-all', 'get', data)
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createTicketGroup(data) {
    return FetchApi('/v1.0/ticket_group', 'post', data);
}
function updateTicketGroup(data) {
    return FetchApi('/v1.0/ticket_group', 'put', data)
}
function getTicketGroupById(data) {
    return FetchApi(`/v1.0/ticket_group/${data}`, 'get')
}
function deleteTicketGroup(data) {
    return FetchApi('/v1.0/ticket_group', 'delete', data)
}

export default {
    getListTicketGroup,
    getAllListTicketGroup,
    createTicketGroup,
    updateTicketGroup,
    getTicketGroupById,
    deleteTicketGroup
}