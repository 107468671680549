import defaults from './default.json';
import sideBar from './sideBar.json';
import formData from './formData.json';
import notify from './notify.json';
import table from './table.json';
import general from './general.json';
import step from './step.json';
import data from './data.json';
import tinyMedia from './tinyMedia.json'

export default {
    ...defaults,
    ...sideBar,
    ...general,
    ...formData,
    ...step,
    ...table,
    ...notify,
    ...data,
    ...tinyMedia
}