import React from "react";
import { Route, Switch } from "react-router-dom";
import { CheckPermission } from "util/CheckPermission";
import asyncComponent from "util/asyncComponent";
const Ticket_Agency = ({ match }) => (
    <Switch>
        {CheckPermission("management_agency") && <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/Ticket_Agency/ListTicketAgency'))} />}
        {CheckPermission("management_agency") && <Route exact path={`${match.url}/list/pending`} component={asyncComponent(() => import('../../components/Ticket_Agency/ListTicketAgencyPending'))} />}
        {CheckPermission("management_agency") && <Route exact path={`${match.url}/list/:user_id`} component={asyncComponent(() => import('../../components/Ticket_Agency/ListTicketAgency'))} />}
        {/* <Route exact path={`${match.url}/create-or-update/:tourist_destination_id/:tour_id`} component={asyncComponent(() => import('../../components/Tour/TourCreate'))} />
        <Route exact path={`${match.url}/create-or-update/:tourist_destination_id`} component={asyncComponent(() => import('../../components/Tour/TourCreate'))} /> */}
        {/* <Route exact path={`${match.url}/create`} component={asyncComponent(() => import('../../components/Tour/TourCreate'))} />
        <Route exact path={`${match.url}/create/:tour_id`} component={asyncComponent(() => import('../../components/Tour/TourCreate'))} />
        <Route exact path={`${match.url}/tour-order`} component={asyncComponent(() => import('../../components/Tour/ListTourOrder'))} /> */}

    </Switch>
)

export default Ticket_Agency;
