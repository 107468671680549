import React from "react";
import { Route, Switch } from "react-router-dom";
import { CheckPermission } from "util/CheckPermission";
import asyncComponent from "util/asyncComponent";

const AccountScanQr = ({ match }) => (
    <Switch>
        {(CheckPermission("create_account_qr") || CheckPermission("update_account_qr") || CheckPermission("delete_account_qr")) && <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/AccountScanQr/ListAccountScanQr'))} />}
        {(CheckPermission("create_account_qr") || CheckPermission("update_account_qr") || CheckPermission("delete_account_qr")) && <Route exact path={`${match.url}/list/:account_id`} component={asyncComponent(() => import('../../components/AccountScanQr/ListAccountScanQrChildren'))} />}
    </Switch>
)

export default AccountScanQr;
