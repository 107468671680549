import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Banner = ({ match }) => (
    <Switch>

        <Route exact path={`${match.url}/place`} component={asyncComponent(() => import('../../components/Banner/ListPlaceAdvertis'))} />
        <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/Banner/ListBanner'))} />

    </Switch>
)

export default Banner;
