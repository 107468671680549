import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Partner = ({ match }) => (
    <Switch>

        {/* <Route exact path={`${match.url}/place`} component={asyncComponent(() => import('../../components/AccountScanQr/ListPlaceAdvertis'))} /> */}
        <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/Ticket/ListPartner'))} />

    </Switch>
)

export default Partner;
