import React, { Component } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";

import SidebarLogo from "./SidebarLogo";
import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import { Icon } from 'antd';
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { connect } from "react-redux";
import { CheckPermission } from "util/CheckPermission";
const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {
  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const { themeType, navStyle, pathname } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile />
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">
              <Menu.Item key="main/dashboard/listing">
                <Link to="/main/dashboard/listing">
                  <i className="icon icon-dasbhoard" />
                  <IntlMessages id="sidebar.dashboard" />
                </Link>
              </Menu.Item>

              {CheckPermission("management_user") && <SubMenu key="user"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-wall " />
                    <IntlMessages id="sidebar.user" />
                  </span>}
              >
                <Menu.Item key="user/list">
                  <Link to="/user/list">
                    <i className="icon icon-wall" />
                    <IntlMessages id="sidebar.user.list" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="user/list/pending">
                  <Link to="/user/list/pending">
                    <i className="icon icon-wall" />
                    <IntlMessages id="sidebar.user.list.pending" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="user/top">
                  <Link to="/user/top">
                    <i className="icon icon-wall" />
                    <IntlMessages id="sidebar.user.top" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="user/top/commission">
                  <Link to="/user/top/commission">
                    <i className="icon icon-wall" />
                    <IntlMessages id="sidebar.commission.top" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="status_mail_gun/list">
                  <Link to="/status_mail_gun/list">
                    <i className="icon icon-wall" />
                    <IntlMessages id="sidebar.status_mail_gun.list" />
                  </Link>
                </Menu.Item>
                {CheckPermission("request_deposit_cart") && <Menu.Item key="request_deposit_cart/list">
                  <Link to="/request_deposit_cart/list">
                    <i className="icon icon-wall" />
                    <IntlMessages id="sidebar.request_deposit_cart.list" />
                  </Link>
                </Menu.Item>}
                <Menu.Item key="user/list_user_special">
                  <Link to="/user/list_user_special">
                    <i className="icon icon-wall" />
                    <IntlMessages id="sidebar.user.list.list_user_special" />
                  </Link>
                </Menu.Item>
              </SubMenu>
              }
              {(CheckPermission("active_refund") || CheckPermission("view_history_trasation")) && <SubMenu key="transaction"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-shopping-cart " />
                    <IntlMessages id="sidebar.transaction" />
                  </span>}
              >
                {CheckPermission("active_refund") && <Menu.Item key="refund/list">
                  <Link to="/refund/list">
                    <i className="icon icon-wall" />
                    <IntlMessages id="sidebar.refund" />
                  </Link>
                </Menu.Item>}
                {CheckPermission("active_refund") && <Menu.Item key="refund_rose_request/list">
                  <Link to="/refund_rose_request/list">
                    <i className="icon icon-wall" />
                    <IntlMessages id="sidebar.refund_rose_request" />
                  </Link>
                </Menu.Item>}
                {CheckPermission("view_history_trasation") && <Menu.Item key="transaction/list">
                  <Link to="/transaction/list">
                    <i className="icon icon-wall" />
                    <IntlMessages id="sidebar.transaction_his" />
                  </Link>
                </Menu.Item>}
              </SubMenu>}


              {/* <SubMenu key="role"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-filter" />
                    <IntlMessages id="sidebar.role" />
                  </span>}
              >

                <Menu.Item key="role/list">
                  <Link to="/role/list">
                    <IntlMessages id="sidebar.role.list" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="permission/list">
                  <Link to="/permission/list">
                    <IntlMessages id="sidebar.permission.list" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="permission/category/list">
                  <Link to="/permission/category/list">
                    <IntlMessages id="sidebar.permission_category.list" />
                  </Link>
                </Menu.Item>
              </SubMenu> */}
              {CheckPermission("management_agency") && <SubMenu key="ticket_agency"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-filter" />
                    <IntlMessages id="sidebar.ticket_agency.list" />
                  </span>}
              >
                {CheckPermission("management_agency") && <Menu.Item key="ticket_agency/list">
                  <Link to="/ticket_agency/list">
                    <i className="icon icon-wall" />
                    <IntlMessages id="sidebar.ticket_agency" />
                  </Link>
                </Menu.Item>}
                {CheckPermission("management_agency") && <Menu.Item key="ticket_agency/list/pending">
                  <Link to="/ticket_agency/list/pending">
                    <i className="icon icon-wall" />
                    <IntlMessages id="sidebar.ticket_agency.pending" />
                  </Link>
                </Menu.Item>}
              </SubMenu>}

              {/* //Quản lý tk Credit */}
              {CheckPermission("create_account_transfer_vietlook_credit") && <SubMenu key="vietlook_credits"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-pricing-table" />
                    <IntlMessages id="sidebar.vietlook_credits" />
                  </span>}
              >
                {CheckPermission("create_account_transfer_vietlook_credit") && <Menu.Item key="vietlook_credits/list_user_transfer_credit">
                  <Link to="/vietlook_credits/list_user_transfer_credit">
                    <IntlMessages id="sidebar.vietlook_credits.list_user_transfer_credit" />
                  </Link>
                </Menu.Item>}
                {CheckPermission("history_transfer_vietlook_credit") && < Menu.Item key="vietlook_credits/history_transfer_credit">
                  <Link to="/vietlook_credits/history_transfer_credit">
                    <IntlMessages id="sidebar.vietlook_credit.history_transfer_credit" />
                  </Link>
                </Menu.Item>}
              </SubMenu>}

              {(CheckPermission("management_place") || CheckPermission("management_destination") || CheckPermission("management_ta_category") || CheckPermission("active_attraction")) && <SubMenu key="place"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    {/* <Icon type="environment" /> */}
                    <i className="icon icon-navigation" />
                    <IntlMessages id="sidebar.place.main" />
                  </span>}
              >
                {CheckPermission("management_place") && <Menu.Item key={"place/area-country"}
                >

                  <Link to="/place/area-country">
                    <IntlMessages id="sidebar.place.location" />
                  </Link>
                </Menu.Item>}

                {CheckPermission("management_destination") && <Menu.Item key="place/tourist-destination">
                  <Link to="/place/tourist-destination">
                    <IntlMessages id="sidebar.place.destination" />
                  </Link>
                </Menu.Item>
                }
                {CheckPermission("active_attraction") && <Menu.Item key="place/active_attraction">
                  <Link to="/place/active_attraction">
                    <IntlMessages id="sidebar.place.active_attraction" />
                  </Link>
                </Menu.Item>}
                {/* <Menu.Item key="service/list">
                  <Link to="/service/list">
                    <IntlMessages id="sidebar.service.list" />
                  </Link>
                </Menu.Item> */}
                {CheckPermission("management_ta_category") && <Menu.Item key="ta_category/list">
                  <Link to="/ta_category/list">
                    <IntlMessages id="sidebar.ta_category.list" />
                  </Link>
                </Menu.Item>}
              </SubMenu>}

              {/* <SubMenu key="hotel"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-company" />
                    <IntlMessages id="sidebar.hotel" />
                  </span>}
              >
                <Menu.Item key="hotel/create-or-update">
                  <Link to="/hotel/create-or-update">
                    <IntlMessages id="sidebar.hotel.create" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="hotel/service/category">
                  <Link to="/hotel/service/category">
                    <IntlMessages id="sidebar.hotel.hservice.category" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="hotel/service/room/category">
                  <Link to="/hotel/service/room/category">
                    <IntlMessages id="sidebar.hotel.hservice.room.category" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="hotel/room/category">
                  <Link to="/hotel/room/category">
                    <IntlMessages id="sidebar.hotel.hroom.category" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="hotel/bed/category">
                  <Link to="/hotel/bed/category">
                    <IntlMessages id="sidebar.hotel.hbed.category" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="hotel/direction">
                  <Link to="/hotel/direction">
                    <IntlMessages id="sidebar.hotel.hdirection" />
                  </Link>
                </Menu.Item>

              </SubMenu> */}

              {(CheckPermission(["create_restaurant"]) || CheckPermission(["update_restaurant"]) || CheckPermission(["delete_restaurant"])) && <SubMenu key="restaurant"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-burger" />
                    <IntlMessages id="sidebar.restaurant" />
                  </span>}
              >
                <Menu.Item key="restaurant/create">
                  <Link to="/restaurant/create">
                    <IntlMessages id="sidebar.restaurant.create" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="restaurant/list">
                  <Link to="/restaurant/list">
                    <IntlMessages id="sidebar.restaurant.list" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="restaurant/category/list">
                  <Link to="/restaurant/category/list">
                    <IntlMessages id="sidebar.restaurant_category.list" />
                  </Link>
                </Menu.Item>
              </SubMenu>}

              {(CheckPermission(["create_store"]) || CheckPermission(["update_store"]) || CheckPermission(["delete_store"])) && <SubMenu key="store"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-noodles" />
                    <IntlMessages id="sidebar.store" />
                  </span>}
              >
                <Menu.Item key="store/create">
                  <Link to="/store/create">
                    <IntlMessages id="sidebar.store.create" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="store/list">
                  <Link to="/store/list">
                    <IntlMessages id="sidebar.store.list" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="store/category/list">
                  <Link to="/store/category/list">
                    <IntlMessages id="sidebar.store_category" />
                  </Link>
                </Menu.Item>
              </SubMenu>}

              {/* <Menu.Item key="hotel/service/category">
                <Link to="/hotel/service/category">
                  <Icon type="container" />
                  <IntlMessages id="sidebar.hotel.hservice.category" />
                </Link>
              </Menu.Item> */}

              {/* <SubMenu key="destination"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-geo-location" />
                    <IntlMessages id="sidebar.destination" />
                  </span>}
              >
                <Menu.Item key="main/destination/location">
                  <Link to="/main/destination/location">
                    <IntlMessages id="sidebar.destination.location" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/destination/place">
                  <Link to="/main/destination/place">
                    <IntlMessages id="sidebar.destination.place" />
                  </Link>
                </Menu.Item>
              </SubMenu> */}
              {(CheckPermission('create_move') || CheckPermission('update_move') || CheckPermission('delete_move') || CheckPermission('create_category_move') || CheckPermission('update_category_move') || CheckPermission('delete_category_move') || CheckPermission('management_service_move')) && <SubMenu key="move"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-cascader" />
                    <IntlMessages id="sidebar.move" />
                  </span>}
              >
                {(CheckPermission('create_move') || CheckPermission('update_move') || CheckPermission('delete_move')) && <Menu.Item key="move/list">
                  <Link to="/move/list">
                    <IntlMessages id="sidebar.move.list" />
                  </Link>
                </Menu.Item>}

                {CheckPermission('management_service_move	') && <Menu.Item key="move/service/list">
                  <Link to="/move/service/list">
                    <IntlMessages id="sidebar.service_move.list" />
                  </Link>
                </Menu.Item>}

                {(CheckPermission('create_category_move') || CheckPermission('update_category_move') || CheckPermission('delete_category_move')) && <Menu.Item key="move/category/list">
                  <Link to="/move/category/list">
                    <IntlMessages id="sidebar.move_category.list" />
                  </Link>
                </Menu.Item>}

                <Menu.Item key="move/car-ticket/cheap">
                  <Link to="/move/car-ticket/cheap">
                    <IntlMessages id="sidebar.move.cheap_car_ticket" />
                  </Link>
                </Menu.Item>
              </SubMenu>}

              {(CheckPermission("create_tour") || CheckPermission("update_tour") || CheckPermission("delete_tour")) && <SubMenu key="tour"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-family" />
                    <IntlMessages id="sidebar.tour" />
                  </span>}
              >
                <Menu.Item key="tour/create">
                  <Link to="/tour/create">
                    <IntlMessages id="sidebar.tour.create" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="tour/list">
                  <Link to="/tour/list">
                    <IntlMessages id="sidebar.tour.list" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="tour/category/list">
                  <Link to="/tour/category/list">
                    <IntlMessages id="sidebar.tour_category.list" />
                  </Link>
                </Menu.Item>
              </SubMenu>}

              {(CheckPermission("create_ticket") || CheckPermission("update_ticket") || CheckPermission("delete_ticket")) &&
                <SubMenu key="ticket"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-sent" />
                      <IntlMessages id="sidebar.ticket" />
                    </span>}
                >
                  <Menu.Item key="ticket/list">
                    <Link to="/ticket/list">
                      <IntlMessages id="sidebar.ticket.list" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="ticket/ticket-order">
                    <Link to="/ticket/ticket-order">
                      <IntlMessages id="sidebar.ticket.booking" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="ticket/get-ticket-warehouse">
                    <Link to="/ticket/get-ticket-warehouse">
                      <IntlMessages id="sidebar.ticket.connection" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="ticket/ticket_type/list">
                    <Link to="/ticket/ticket_type/list">
                      <IntlMessages id="sidebar.ticket_type" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="partner/list">
                    <Link to="/partner/list">
                      <IntlMessages id="sidebar.ticket.partner" />
                    </Link>
                  </Menu.Item>
                </SubMenu>}

              {/* {(CheckPermission("create_order") || CheckPermission("update_order") || CheckPermission("delete_order")) && */}
              {CheckPermission("list_order") && <SubMenu key="order"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-shopping-cart" />
                    <IntlMessages id="sidebar.order" />
                  </span>}
              >
                <Menu.Item key="order/ticket_order_booking/list">
                  <Link to="/order/ticket_order_booking/list">
                    <IntlMessages id="sidebar.ticket_order" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="order/invoice/list">
                  <Link to="/order/invoice/list">
                    <IntlMessages id="sidebar.invoice" />
                  </Link>
                </Menu.Item>
              </SubMenu>
              }
              {/* } */}

              {/* <SubMenu key="service"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-calendar" />
                    <IntlMessages id="sidebar.service" />
                  </span>}
              >

              </SubMenu> */}
              {/* quản lí banner quảng cáo */}
              {(CheckPermission("create_banner") || CheckPermission("update_banner") || CheckPermission("delete_banner")) && <SubMenu key="banner"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-alert" />
                    <IntlMessages id="sidebar.banner" />
                  </span>}
              >
                <Menu.Item key="banner/list">
                  <Link to="/banner/list">
                    <IntlMessages id="sidebar.banner.list" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="banner_sale/list">
                  <Link to="/banner_sale/list">
                    <IntlMessages id="sidebar.banner_sale.list" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="banner/place">
                  <Link to="/banner/place">
                    <IntlMessages id="sidebar.banner.place" />
                  </Link>
                </Menu.Item>

              </SubMenu>}

              {/* Quản lý tin tức & blog */}
              {(CheckPermission("view_post") || CheckPermission("active_post") || CheckPermission("create_post") || CheckPermission("update_post") || CheckPermission("delete_post") || CheckPermission("update_blog") || CheckPermission("create_blog") || CheckPermission("delete_blog")) && <SubMenu key="post-and-blog"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-apps-new" />
                    <IntlMessages id="sidebar.post-blog" />
                  </span>}
              >
                {(CheckPermission("create_post") || CheckPermission("update_post") || CheckPermission("create_blog") || CheckPermission("update_blog")) && <Menu.Item key="post-and-blog/create">
                  <Link to="/post-and-blog/create">
                    <IntlMessages id="sidebar.post-blog.create" />
                  </Link>
                </Menu.Item>}

                <Menu.Item key="post-and-blog/list">
                  <Link to="/post-and-blog/list">
                    <IntlMessages id="sidebar.post-blog.list" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="post-and-blog/category">
                  <Link to="/post-and-blog/category">
                    <IntlMessages id="sidebar.post-blog.category" />
                  </Link>
                </Menu.Item>
              </SubMenu>}

              {/* Quản lý câu hỏi khách hàng*/}
              {CheckPermission("rep_customer") && <SubMenu key="customer"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-wall" />
                    <IntlMessages id="sidebar.customer" />
                  </span>}
              >
                <Menu.Item key="customer/request-customer">
                  <Link to="/customer/request-customer">
                    <IntlMessages id="sidebar.customer.request-customer" />
                  </Link>
                </Menu.Item>

              </SubMenu>
              }

              {/* <Menu.Item key="main/promotion">
                <Link to="/main/promotion">
                  <i className="icon icon-ticket-new" />
                  <IntlMessages id="sidebar.promotion" />
                </Link>
              </Menu.Item> */}

              {/* <Menu.Item key="main/others">
                <Link to="/main/others">
                  <i className="icon icon-ellipse-h" />
                  <IntlMessages id="sidebar.others" />
                </Link>
              </Menu.Item> */}

              {(CheckPermission("create_account_qr") || CheckPermission("update_account_qr") || CheckPermission("delete_account_qr")) &&
                <Menu.Item key="account_scan_qr/list">
                  <Link to="/account_scan_qr/list">
                    <i className="icon icon-camera-2" />
                    <IntlMessages id="sidebar.account_scan_qr" />
                  </Link>
                </Menu.Item>}

              {CheckPermission("admin") && <Menu.Item key="rating/list">
                <Link to="/rating/list">
                  <i className="icon icon-chat icon-fw icon-xl" />
                  <IntlMessages id="sidebar.comment" />
                </Link>
              </Menu.Item>
              }

              {/* <SubMenu key="statistics"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-stats" />
                    <IntlMessages id="sidebar.statistics" />
                  </span>}
              >
                <Menu.Item key="main/statistics/revenue">
                  <Link to="/main/statistics/revenue">
                    <IntlMessages id="sidebar.statistics.revenue" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/statistics/order">
                  <Link to="/main/statistics/order">
                    <IntlMessages id="sidebar.statistics.order" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="main/statistics/customer">
                  <Link to="/main/statistics/customer">
                    <IntlMessages id="sidebar.statistics.customer" />
                  </Link>
                </Menu.Item>
              </SubMenu> */}

              {CheckPermission("admin") && <SubMenu key="administration"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-team" />
                    <IntlMessages id="sidebar.administration" />
                  </span>}
              >
                <Menu.Item key="role/list">
                  <Link to="/role/list">
                    <IntlMessages id="sidebar.administration.role" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="permission/list">
                  <Link to="/permission/list">
                    <IntlMessages id="sidebar.administration.permission" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="permission/category/list">
                  <Link to="/permission/category/list">
                    <IntlMessages id="sidebar.permission_category.list" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="user_role/list">
                  <Link to="/user_role/list">
                    <IntlMessages id="sidebar.administration" />
                  </Link>
                </Menu.Item>
                {CheckPermission('system') && <Menu.Item key="user_role/admin/list">
                  <Link to="/user_role/admin/list">
                    <IntlMessages id="sidebar.administration.admin" />
                  </Link>
                </Menu.Item>}
              </SubMenu>}

              {/*Cài đặt cho app*/}
              {CheckPermission("admin") && <SubMenu key="setting"
                className={this.getNavStyleSubMenuClass(navStyle)}
                title={
                  <span>
                    <i className="icon icon-setting" />
                    <IntlMessages id="sidebar.setting" />
                  </span>}
              >
                <Menu.Item key="setting/version-app">
                  <Link to="/setting/version-app">
                    <IntlMessages id="sidebar.setting.version" />
                  </Link>
                </Menu.Item>

              </SubMenu>}

            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary >
    );
  }
}

SidebarContent.propTypes = {};

const mapStateToProps = ({ settings }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  return { navStyle, themeType, locale, pathname }
};

export default connect(mapStateToProps)(SidebarContent);