import { FetchApi } from '../../axios';

function getListProvince(data) {
    return FetchApi('/v1.0/provinces', 'get', data)
}

function deleteProvince(data) {
    return FetchApi('/v1.0/provinces', 'delete', data)
}

function createProvince(data) {
    return FetchApi('/v1.0/provinces', 'post', data)
}

function updateProvince(data) {
    return FetchApi('/v1.0/provinces', 'put', data)
}

function getProvinceByID(data) {
    return FetchApi(`/v1.0/provinces/${data}`, 'get')
}

function getProvinceActive(data) {
    return FetchApi(`/v1.0/provinces/active`, 'get', data)
}

function getProvinceName(data) {
    return FetchApi('/v1.0/provinces/getName', 'get', data)
}

export default {
    getListProvince,
    deleteProvince,
    createProvince,
    updateProvince,
    getProvinceByID,
    getProvinceActive,
    getProvinceName
}