import { FetchApi } from '../../axios';


function getListTicketPriceMaxDay(data) {
    return FetchApi('/v1.0/ticket_price_max_day', 'get', data)
}
function getAllListTicketPriceMaxDay(data) {
    return FetchApi('/v1.0/ticket_price_max_day/list/get-list-all', 'get', data)
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createTicketPriceMaxDay(data) {
    return FetchApi('/v1.0/ticket_price_max_day', 'post', data);
}
function updateTicketPriceMaxDay(data) {
    return FetchApi('/v1.0/ticket_price_max_day', 'put', data)
}
function getTicketPriceMaxDayById(data) {
    return FetchApi(`/v1.0/ticket_price_max_day/${data}`, 'get')
}
function getHistoryChangeMaxDay(data) {
    return FetchApi('/v1.0/ticket_price_max_day/get-history-max_change/get-all', 'get', data)
}
function deleteTicketPriceMaxDay(data) {
    return FetchApi('/v1.0/ticket_price_max_day', 'delete', data)
}

export default {
    getListTicketPriceMaxDay,
    getAllListTicketPriceMaxDay,
    createTicketPriceMaxDay,
    updateTicketPriceMaxDay,
    getHistoryChangeMaxDay,
    getTicketPriceMaxDayById,
    deleteTicketPriceMaxDay
}