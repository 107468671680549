import React from "react";
import { Route, Switch } from "react-router-dom";
import { CheckPermission } from "util/CheckPermission";
import asyncComponent from "util/asyncComponent";
const Permission = ({ match }) => (
    <Switch>
        {CheckPermission("admin") && <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/Permission/ListPermission'))} />}
        {CheckPermission("admin") && <Route exact path={`${match.url}/category/list`} component={asyncComponent(() => import('../../components/Permission/ListCategoryPermission'))} />}

    </Switch>
)

export default Permission;