import { FetchApi } from '../../axios';


function getListTACategory(data) {
    return FetchApi('/v1.0/ta_category', 'get', data)
}
function getAllListTACategory(data) {
    return FetchApi('/v1.0/ta_category/list/get-list-all', 'get', data)
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createTACategory(data) {
    return FetchApi('/v1.0/ta_category', 'post', data);
}
function updateTACategory(data) {
    return FetchApi('/v1.0/ta_category', 'put', data)
}
function getTACategoryById(data) {
    return FetchApi(`/v1.0/ta_category/${data}`, 'get')
}
function deleteTACategory(data) {
    return FetchApi('/v1.0/ta_category', 'delete', data)
}

export default {
    getListTACategory,
    getAllListTACategory,
    createTACategory,
    updateTACategory,
    getTACategoryById,
    deleteTACategory
}