import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { T } from 'util';
import { Avatar, Popover, Button } from "antd";
import { userSignOut } from "appRedux/actions/Auth";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false
    }
  }

  display = () => {
    this.setState({
      display: false
    })
  }

  handleVisibleChange = display => {
    this.setState({ display });
  };

  render() {
    let { profile } = this.props;
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => this.display()}><Link to="/my-account/profile"><T id="app.userAuth.my-account"></T></Link></li>
        <li><T id="app.userAuth.connection"></T></li>
        <li onClick={() => this.props.userSignOut()}><T id="app.userAuth.logout"></T>
        </li>
        {/* <li>
          <div class="dt-avatar-wrapper flex-nowrap p-6 mt--5 bg-gradient-purple text-white rounded-top">
            <img class="dt-avatar" src="" alt="Tungpt" />
            <span class="dt-avatar-info">
              <span class="dt-avatar-name">Tungpt.102</span>
              <span class="f-12">Administrator</span>
            </span>
          </div>
        </li>
        <li><i class="icon icon-user-o icon-fw mr-2 mr-sm-1"></i>  Account</li>
        <li><i class="icon icon-setting icon-fw mr-2 mr-sm-1"></i>  Setting</li>
        <li onClick={() => this.props.userSignOut()}><i class="icon icon-edit icon-fw mr-2 mr-sm-1"></i>  Logout</li> */}
      </ul >
    );

    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click" visible={this.state.display} onVisibleChange={this.handleVisibleChange}>
          <Avatar src={profile.avatar}
            className="gx-size-40 gx-pointer gx-mr-3" alt="" />
          <span className="gx-avatar-name">{profile && profile.email}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
        </Popover>
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { profile } = auth;
  return { profile }
};

export default connect(mapStateToProps, { userSignOut })(UserProfile);
