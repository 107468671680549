import React from "react";
import { Route, Switch } from "react-router-dom";
import { CheckPermission } from "util/CheckPermission";
import asyncComponent from "util/asyncComponent";

const Restaurant = ({ match }) => (
    <Switch>
        {CheckPermission("create_restaurant") && <Route exact path={`${match.url}/create`} component={asyncComponent(() => import('../../components/Restaurant/RestaurantCreate'))} />}
        {CheckPermission("update_restaurant") && <Route exact path={`${match.url}/create/:restaurant_id`} component={asyncComponent(() => import('../../components/Restaurant/RestaurantCreate'))} />}
        <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/Restaurant/RestaurantList'))} />
        {/* {CheckPermission("create_restaurant") && CheckPermission("update_restaurant") && <Route exact path={`${match.url}/:sub_id/menu`} component={asyncComponent(() => import('../../components/Restaurant/ListMenu'))} />} */}
        {(CheckPermission("create_restaurant") || CheckPermission("update_restaurant") || CheckPermission("delete_restaurant")) && < Route exact path={`${match.url}/category/list`} component={asyncComponent(() => import('../../components/Restaurant/ListCategoryMenu'))} />}
        {/* {CheckPermission("create_restaurant") && CheckPermission("update_restaurant") && <Route exact path={`${match.url}/:sub_id/menu/:menu_id`} component={asyncComponent(() => import('../../components/Restaurant/ListFoodOfMenu'))} />}
        {CheckPermission("create_restaurant") && CheckPermission("update_restaurant") && <Route exact path={`${match.url}/:sub_id/food`} component={asyncComponent(() => import('../../components/Restaurant/ListFood'))} />} */}
    </Switch>
)

export default Restaurant;
