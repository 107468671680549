import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  UPDATE_USER,
  SIGNIN_USER_BY_TOKEN,
  SIGNIN_USER_BY_TOKEN_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  LOAD_AUTHEN
} from "constants/ActionTypes";

export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};
export const userUpdate = (profile) => {
  return {
    type: UPDATE_USER,
    profile
  };
};

export const userSignInByToken = () => {
  return {
    type: SIGNIN_USER_BY_TOKEN,
  };
};

export const userSignInByTokenSuccess = (token, profile) => {
  return {
    type: SIGNIN_USER_BY_TOKEN_SUCCESS,
    token, profile
  };
};

export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};

export const userSignInSuccess = (token, profile) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    token, profile
  }
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const loadAuthen = (state) => {
  return {
    type: LOAD_AUTHEN,
    payload: state
  };
};
