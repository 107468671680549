import React from "react";
import { Route, Switch } from "react-router-dom";
import { CheckPermission } from "util/CheckPermission";
import asyncComponent from "util/asyncComponent";

const Store = ({ match }) => (
    <Switch>
       {CheckPermission("create_store") && <Route exact path={`${match.url}/create`} component={asyncComponent(() => import('../../components/Store/StoreCreate'))} />}
       {CheckPermission("update_store") &&<Route exact path={`${match.url}/create/:store_id`} component={asyncComponent(() => import('../../components/Store/StoreCreate'))} />}
        <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/Store/ListStore'))} />
        <Route exact path={`${match.url}/:sub_id/catalogue`} component={asyncComponent(() => import('../../components/Store/ListStoreCatalogue'))} />
        {(CheckPermission("create_store") || CheckPermission("update_store") || CheckPermission("delete_store")) &&<Route exact path={`${match.url}/category/list`} component={asyncComponent(() => import('../../components/Store/ListStoreCategory'))} />}
        <Route exact path={`${match.url}/:sub_id/store_catalogue/:store_catalogue_id`} component={asyncComponent(() => import('../../components/Store/ListItemOfCatalogue'))} />
        <Route exact path={`${match.url}/:sub_id/catalogue_item`} component={asyncComponent(() => import('../../components/Store/ListStoreCatelogueItem'))} />
    </Switch>
)

export default Store;
