import React from "react";
import { Route, Switch } from "react-router-dom";
import Place from './place';
import Main from './main';
import Ticket from './ticket';
import Partner from './partner';
import TA_Category from './ta_category';
import RefundRequest from './refund_request';
import RefundRoseRequest from './refund_rose_request';
import RequestDepositCart from './request_deposit_cart';
import StatusMailGun from './status_mail_gun';
import Role from './role';
import UserRole from './user_role';
import Permission from './permission';
import AccountScanQr from './account_scan_qr';
import Tour from './tour';
import Service from './service';
import Hotel from './hotel';
import PostAndBlog from './post&blog';
import User from './user';
import Ticket_Agency from './ticket_agency';
import Profile from './profile';
import Developing from './developing';
import Banner from './banner';
import BannerSale from './banner_sale';
import Rating from './rating';
import Restaurant from './restaurant';
import Store from './store';
import Move from './move';
import Customer from './customer';
import Transaction from './transaction';
import Order from './order';
import VietLook_Credits from './vietlook_credits';
import Setting from './setting';
const App = ({ match }) => (
  <Switch>
    {/* <Route path={`${match.url}main`} component={() => <div className="gx-page-error-container">
      <div className="gx-page-error-content">
        <div style={{ fontWeight: 'bold', paddingLeft: 100, fontSize: 25 }} className="gx-error-code-text-center">DEVELOPING...</div>
        <h2 className="gx-text-center">
          Tính năng này đang phát triển!!
                    </h2>

        <p className="gx-text-center">

        </p>
      </div>
    </div>} /> */}
    <Route path={`${match.url}my-account`} component={Profile} />
    <Route path={`${match.url}place`} component={Place} />
    <Route path={`${match.url}main`} component={Main} />
    <Route path={`${match.url}ticket`} component={Ticket} />
    <Route path={`${match.url}account_scan_qr`} component={AccountScanQr} />
    <Route path={`${match.url}tour`} component={Tour} />
    <Route path={`${match.url}ta_category`} component={TA_Category} />
    <Route path={`${match.url}refund`} component={RefundRequest} />
    <Route path={`${match.url}status_mail_gun`} component={StatusMailGun} />
    <Route path={`${match.url}refund_rose_request`} component={RefundRoseRequest} />
    <Route path={`${match.url}request_deposit_cart`} component={RequestDepositCart} />
    <Route path={`${match.url}transaction`} component={Transaction} />
    <Route path={`${match.url}role`} component={Role} />
    <Route path={`${match.url}user_role`} component={UserRole} />
    <Route path={`${match.url}permission`} component={Permission} />
    <Route path={`${match.url}partner`} component={Partner} />
    <Route path={`${match.url}user`} component={User} />
    <Route path={`${match.url}ticket_agency`} component={Ticket_Agency} />
    <Route path={`${match.url}rating`} component={Rating} />
    <Route path={`${match.url}service`} component={Service} />
    <Route path={`${match.url}hotel`} component={Hotel} />
    <Route path={`${match.url}post-and-blog`} component={PostAndBlog} />
    <Route path={`${match.url}restaurant`} component={Restaurant} />
    <Route path={`${match.url}store`} component={Store} />
    <Route path={`${match.url}move`} component={Move} />
    <Route path={`${match.url}banner`} component={Banner} />
    <Route path={`${match.url}banner_sale`} component={BannerSale} />
    <Route path={`${match.url}customer`} component={Customer} />
    <Route path={`${match.url}order`} component={Order} />
    <Route path={`${match.url}vietlook_credits`} component={VietLook_Credits} />
    <Route path={`${match.url}setting`} component={Setting} />
  </Switch>
)

export default App;
