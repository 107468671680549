import { FetchApi } from '../../axios';

function getListStreet(data) {
    return FetchApi('/v1.0/streets', 'get', data)
}

function deleteStreet(data) {
    return FetchApi('/v1.0/streets', 'delete', data)
}

function createStreet(data) {
    return FetchApi('/v1.0/streets', 'post', data)
}

function updateStreet(data) {
    return FetchApi('/v1.0/streets', 'put', data)
}

function getStreetByID(data) {
    return FetchApi(`/v1.0/streets/${data}`, 'get')
}

export default{
    getListStreet,
    deleteStreet,
    createStreet,
    updateStreet,
    getStreetByID
}