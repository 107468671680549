import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import { CheckPermission } from "util/CheckPermission";
const RequestDepositCart = ({ match }) => (
    <Switch>
        {CheckPermission('request_deposit_cart') && <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/RequestDepositCart/ListRequestDepositCart'))} />}
        {CheckPermission('request_deposit_cart') && <Route exact path={`${match.url}/list/:id`} component={asyncComponent(() => import('../../components/RequestDepositCart/ListRequestDepositCart'))} />}

    </Switch>
)

export default RequestDepositCart;