import { FetchApi } from "../../axios";

function createRestaurant(data) {
  return FetchApi("/v1.0/restaurant/create", "post", data);
}

function getRestaurantList(data) {
  return FetchApi("/v1.0/restaurant/list", "get", data);
}
function getAllRestaurant(data) {
  return FetchApi("/v1.0/restaurant/list/all", "get", data);
}

function getRestaurantByID(data) {
  return FetchApi("/v1.0/restaurant/get-infor", "get", data);
}
function getNameRestaurantByID(data) {
  return FetchApi("/v1.0/restaurant/get-infor/get-name", "get", data);
}

function updateRestaurant(data) {
  return FetchApi("/v1.0/restaurant/update", "put", data);
}

function deleteRestaurant(data) {
  return FetchApi("/v1.0/restaurant/del", "delete", data);
}

function getAllDestinations(data) {
  return FetchApi("/v1.0/restaurant/get-all-destinations", "get", data);
}
//Category menu
// function getListMenu(data) {
//     return FetchApi('/v1.0/rmenu', 'get', data);
// }
function getListCategoryMenu(data) {
  return FetchApi("/v1.0/rcategorymenu", "get", data);
}
function getAllListCategoryMenu(data) {
  return FetchApi("/v1.0/rcategorymenu/list/get-list-all", "get", data);
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createCategoryMenu(data) {
  return FetchApi("/v1.0/rcategorymenu", "post", data);
}
function updateCategoryMenu(data) {
  return FetchApi("/v1.0/rcategorymenu", "put", data);
}
function getCategoryMenuById(data) {
  return FetchApi(`/v1.0/rcategorymenu/${data}`, "get");
}
function deleteCategoryMenu(data) {
  return FetchApi("/v1.0/rcategorymenu", "delete", data);
}

//api menu
function getListMenu(data) {
  return FetchApi("/v1.0/rmenu", "get", data);
}
function getAllListMenu(data) {
  return FetchApi("/v1.0/rmenu/list/get-list-all", "get", data);
}
function getFoodOfMenu(data) {
  return FetchApi("/v1.0/rmenu/list/get-food-of-menu", "get", data);
}
function createMenu(data) {
  return FetchApi("/v1.0/rmenu", "post", data);
}
function updateMenu(data) {
  return FetchApi("/v1.0/rmenu", "put", data);
}
function getMenuById(data) {
  return FetchApi(`/v1.0/rmenu/${data}`, "get");
}
function deleteMenu(data) {
  return FetchApi("/v1.0/rmenu", "delete", data);
}

//api food
function getListFood(data) {
  return FetchApi("/v1.0/rfood", "get", data);
}
function getAllListFood(data) {
  return FetchApi("/v1.0/rfood/list/get-list-all", "get", data);
}
function createFood(data) {
  return FetchApi("/v1.0/rfood", "post", data);
}
function updateFood(data) {
  return FetchApi("/v1.0/rfood", "put", data);
}
function getFoodById(data) {
  return FetchApi(`/v1.0/rfood/${data}`, "get");
}
function deleteFood(data) {
  return FetchApi("/v1.0/rfood", "delete", data);
}
function deleteFoodOfMenu(data) {
  return FetchApi("/v1.0/rfood/food-of-menu", "delete", data);
}

export default {
  createRestaurant,
  getRestaurantList,
  getAllRestaurant,
  getRestaurantByID,
  getNameRestaurantByID,
  updateRestaurant,
  deleteRestaurant,
  getAllDestinations,
  getListMenu,
  getAllListMenu,
  createMenu,
  updateMenu,
  getFoodOfMenu,
  getMenuById,
  deleteMenu,
  getListFood,
  getAllListFood,
  createFood,
  updateFood,
  getFoodById,
  deleteFood,
  getListCategoryMenu,
  getAllListCategoryMenu,
  createCategoryMenu,
  updateCategoryMenu,
  getCategoryMenuById,
  deleteCategoryMenu,
  deleteFoodOfMenu
};
