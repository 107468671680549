import { FetchApi } from '../../axios';

function getListTicket(data) {
    return FetchApi('/v1.0/tickets', 'get', data)
}
function getListTicketOfAttraction(data) {
    return FetchApi('/v1.0/tickets/get-list/ticket-of-attraction', 'get', data)
}
function getAllTicket(data) {
    return FetchApi('/v1.0/tickets/list/all', 'get', data)
}

function deleteTicket(data) {
    return FetchApi('/v1.0/tickets', 'delete', data)
}

function createTicket(data) {
    return FetchApi('/v1.0/tickets', 'post', data)
}

function updateTicket(data) {
    return FetchApi('/v1.0/tickets', 'put', data)
}

function getTicketByID(data) {
    return FetchApi(`/v1.0/tickets/${data}`, 'get')
}

function getTicketActive(data) {
    return FetchApi(`/v1.0/tickets/active`, 'get', data)
}
//get Type Ticket
function getListTicketType(data) {
    return FetchApi(`/v1.0/ticket_type/get-list-type`, 'get', data)
}
function getAllListTicketType(data) {
    return FetchApi(`/v1.0/ticket_type/list/all`, 'get', data)
}
function createTicketType(data) {
    return FetchApi('/v1.0/ticket_type', 'post', data)
}

function updateTicketType(data) {
    return FetchApi('/v1.0/ticket_type', 'put', data)
}
function deleteTicketType(data) {
    return FetchApi('/v1.0/ticket_type', 'delete', data)
}
function getTicketTypeByID(data) {
    return FetchApi(`/v1.0/ticket_type/${data}`, 'get')
}
//get Type Ticket Type WareHouse
function getListTicketTypeWareHouse(data) {
    return FetchApi(`/v1.0/ticket_type_warehouse/get-list-type-warehouse`, 'get', data)
}
function getAllListTicketTypeWareHouse(data) {
    return FetchApi(`/v1.0/ticket_type_warehouse/list/all`, 'get', data)
}
function getSearchAllListTicketTypeWareHouse(data) {
    return FetchApi(`/v1.0/ticket_type_warehouse/list_search/choose`, 'get', data)
}
function createTicketTypeWareHouse(data) {
    return FetchApi('/v1.0/ticket_type_warehouse', 'post', data)
}
function updateTicketTypeWareHouse(data) {
    return FetchApi('/v1.0/ticket_type_warehouse', 'put', data)
}
function deleteTicketTypeWareHouse(data) {
    return FetchApi('/v1.0/ticket_type_warehouse', 'delete', data)
}
function getTicketTypeWareHouseByID(data) {
    return FetchApi(`/v1.0/ticket_type_warehouse/${data}`, 'get')
}
//get ticket warehouse
function getListTicketWareHouse(data) {
    return FetchApi(`/v1.0/ticket_warehouse`, 'get', data)
}
function createTicketWareHouse(data) {
    return FetchApi('/v1.0/ticket_warehouse', 'post', data)
}

function updateTicketWareHouse(data) {
    return FetchApi('/v1.0/ticket_warehouse', 'put', data)
}
function deleteTicketWareHouse(data) {
    return FetchApi('/v1.0/ticket_warehouse', 'delete', data)
}
function getTicketWareHouseByID(data) {
    return FetchApi(`/v1.0/ticket_warehouse/${data}`, 'get')
}
//get ticket_order
function getListTicketOrder(data) {
    return FetchApi('/v1.0/ticket_order', 'get', data)
}
function getCountTicketOrderByMonth(data) {
    return FetchApi('/v1.0/ticket_order/get-all/count-ticket-order', 'get', data)
}
function getCountTicketOrderToday(data) {
    return FetchApi('/v1.0/ticket_order/get-all/count-ticket-order-today', 'get', data)
}
function getDetailTicketOrder(data) {
    return FetchApi('/v1.0/ticket_order_detail/detail-ticket-order', 'get', data)
}
function getListTicketOrderBooking(data) {
    return FetchApi(`/v1.0/ticket_order_booking`, 'get', data)
}
//import excel
function createTableTest(data) {
    return FetchApi('/v1.0/ticket_warehouse/createTableTest', 'post', data)
}
//update date
function updateDateTicket(data) {
    return FetchApi('/v1.0/ticket_order/update-date', 'put', data)
}
function getListTicketWareHouseByID(data) {
    return FetchApi('/v1.0/ticket_order_booking/get-list-ticket-warehouse', 'get', data)
}
function updateDateTicketWareHouse(data) {
    return FetchApi('/v1.0/ticket_order_booking/update-date-ticket-warehouse', 'put', data)
}


function getListInvoice(data) {
    return FetchApi(`/v1.0/invoice`, 'get', data)
}
function updateInvoice(data) {
    return FetchApi(`/v1.0/invoice/update`, 'put', data)
}

export default {
    getListTicket,
    getAllListTicketType,
    getAllTicket,
    getListTicketOfAttraction,
    getCountTicketOrderToday,
    createTicketWareHouse,
    updateTicketWareHouse,
    getListTicketWareHouse,
    getTicketWareHouseByID,
    deleteTicketWareHouse,
    getListTicketType,
    getTicketTypeByID,
    updateTicketType,
    createTicketType,
    deleteTicketType,
    deleteTicket,
    createTicket,
    updateTicket,
    getTicketByID,
    getTicketActive,
    getListTicketTypeWareHouse,
    getAllListTicketTypeWareHouse,
    updateTicketTypeWareHouse,
    deleteTicketTypeWareHouse,
    createTicketTypeWareHouse,
    getTicketTypeWareHouseByID,
    getCountTicketOrderByMonth,
    getListTicketOrder,
    getDetailTicketOrder,
    createTableTest,
    getSearchAllListTicketTypeWareHouse,
    getListTicketOrderBooking,
    updateDateTicket,
    getListTicketWareHouseByID,
    updateDateTicketWareHouse,
    getListInvoice,
    updateInvoice,
}