import { FetchApi } from '../../axios';

function getListHCategoryService(data) {
    return FetchApi('/v1.0/hcategory_service', 'get', data)
}

function deleteHCategoryService(data) {
    return FetchApi('/v1.0/hcategory_service', 'delete', data)
}

function createHCategoryService(data) {
    return FetchApi('/v1.0/hcategory_service', 'post', data)
}

function updateHCategoryService(data) {
    return FetchApi('/v1.0/hcategory_service', 'put', data)
}

function getHCategoryServiceByID(data) {
    return FetchApi(`/v1.0/hcategory_service/${data}`, 'get')
}

export default {
    getListHCategoryService,
    deleteHCategoryService,
    createHCategoryService,
    updateHCategoryService,
    getHCategoryServiceByID
}