import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
const StatusMailGun = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/SendMail/ListStatusMailGun'))} />

    </Switch>
)

export default StatusMailGun;