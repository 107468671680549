import { FetchApi } from '../../axios';


function getListPermission(data) {
    return FetchApi('/v1.0/permission', 'get', data)
}
function getAllListPermission(data) {
    return FetchApi('/v1.0/permission/list/get-list-all', 'get', data)
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createPermission(data) {
    return FetchApi('/v1.0/permission', 'post', data);
}
function updatePermission(data) {
    return FetchApi('/v1.0/permission', 'put', data)
}
function getPermissionById(data) {
    return FetchApi(`/v1.0/permission/${data}`, 'get')
}
function deletePermission(data) {
    return FetchApi('/v1.0/permission', 'delete', data)
}

export default {
    getListPermission,
    getAllListPermission,
    createPermission,
    updatePermission,
    getPermissionById,
    deletePermission
}