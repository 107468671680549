import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
const Profile = ({match}) => (
    <Switch>
        <Route exact path={`${match.url}/profile`} component={asyncComponent(() => import('../../components/profile'))} />

    </Switch>
)

export default Profile;