import { FetchApi } from '../../axios';

function getListDashboard(data) {
    return FetchApi('/v1.0/dashboard/get-listing', 'get', data)
}

function get7DayUser(data) {
    return FetchApi('/v1.0/dashboard/get-user-7day', 'get', data)
}

function getUserActive(data) {
    return FetchApi('/v1.0/dashboard/get-user-active', 'get', data)
}

function getUserActiveMonth(data) {
    return FetchApi('/v1.0/dashboard/get-user-active_month', 'get', data)
}

function getTopUser(data) {
    return FetchApi('/v1.0/dashboard/get-top-user', 'get', data)
}
function getTopCommission(data) {
    return FetchApi('/v1.0/dashboard/get-top-commission', 'get', data)
}

function getListUserIntroduce(data) {
    return FetchApi('/v1.0/dashboard/get-list-user-introduce', 'get', data)
}
export default {
    getListDashboard,
    get7DayUser,
    getUserActive,
    getTopCommission,
    getUserActiveMonth,
    getTopUser,
    getListUserIntroduce
}