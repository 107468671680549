import { FetchApi } from '../../axios';

function getListCountry(data) {
    return FetchApi('/v1.0/countrys', 'get', data)
}

function deleteCountry(data) {
    return FetchApi('/v1.0/countrys', 'delete', data)
}

function createCountry(data) {
    return FetchApi('/v1.0/countrys', 'post', data)
}

function updateCountry(data) {
    return FetchApi('/v1.0/countrys', 'put', data)
}

function getCountryByID(data) {
    return FetchApi(`/v1.0/countrys/${data}`, 'get')
}
function getNameCountryByID(data) {
    return FetchApi(`/v1.0/countrys/${data}/get-name`, 'get')
}

export default {
    getListCountry,
    deleteCountry,
    createCountry,
    updateCountry,
    getCountryByID,
    getNameCountryByID
}