import React from "react";
import {
  Button, Form,
  // Icon,
  Input, message
} from "antd";
import { connect } from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  userSignIn
} from "appRedux/actions/Auth";

import {injectIntl} from "react-intl";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

class SignIn extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.showAuthLoader();
        this.props.userSignIn(values);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showMessage, loader, alertMessage ,intl} = this.props;
    return (
      <div className="gx-app-login-wrap">

        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src="https://taoanhonline.com/wp-content/uploads/2019/08/hinh-nen-bau-troi-122.jpg" alt='Neature' />
              </div>
              <div className="gx-app-logo-wid">
                <h1><i className="icon icon-user" /> <IntlMessages id="app.userAuth.signIn" /></h1>
                <p><i className="icon icon-check" /> <IntlMessages id="app.userAuth.bySigning" /></p>
                <p><i className="icon icon-check" /> <IntlMessages id="app.userAuth.getAccount" /></p>
              </div>
              <div className="gx-app-logo image-logo">
                <img alt="example" src={require("assets/images/logo-vietlook.png")} />
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0" >
                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{
                      required: true, type: 'email', message: <IntlMessages id="formData.validate.email" />,
                    }],
                  })(
                    <Input placeholder={intl.formatMessage({id:"formData.placeholder.email"})}/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: <IntlMessages id="formData.validate.password" /> }],
                  })(
                    <Input type="password" placeholder={intl.formatMessage({id:"formData.placeholder.password"})} />
                  )}
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.login" />
                  </Button>
                </FormItem>
              </Form>
            </div>

            {loader ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(injectIntl(SignIn));

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader
})(WrappedNormalLoginForm);
