import React from "react";
import { Route, Switch } from "react-router-dom";
import { CheckPermission } from "util/CheckPermission";
import asyncComponent from "util/asyncComponent";
const Place = ({ match }) => (
    <Switch>
        {(CheckPermission('create_post') || CheckPermission('create_blog')) && <Route exact path={`${match.url}/create`} component={asyncComponent(() => import('../../components/Post&Blog/CreateOrUpdatePost'))} />}
        {(CheckPermission("active_post") || CheckPermission("view_post") || CheckPermission("create_post") || CheckPermission("update_post") || CheckPermission("delete_post") || CheckPermission("delete_blog") || CheckPermission("update_blog") || CheckPermission("create_blog")) && <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/Post&Blog/ListPost'))} />}
        {(CheckPermission("active_post") || CheckPermission("view_post") || CheckPermission('update_post') || CheckPermission("delete_blog") || CheckPermission("update_blog") || CheckPermission("create_blog")) && <Route exact path={`${match.url}/update/:post_id`} component={asyncComponent(() => import('../../components/Post&Blog/CreateOrUpdatePost'))} />}
        <Route exact path={`${match.url}/category`} component={asyncComponent(() => import('../../components/Post&Blog/ListCategory'))} />
        <Route exact path={`${match.url}/category/:category_id`} component={asyncComponent(() => import('../../components/Post&Blog/ListCategoryChildren'))} />
    </Switch>
)

export default Place;
