import React from "react";
import { Route, Switch } from "react-router-dom";
import { CheckPermission } from "util/CheckPermission";
import asyncComponent from "util/asyncComponent";
const Ticket = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}/get-ticket-warehouse/importExcel`} component={asyncComponent(() => import('../../components/Ticket/ListTableTest'))} />
        <Route exact path={`${match.url}/get-ticket-warehouse/importExcel/result`} component={asyncComponent(() => import('../../components/Ticket/ListTableTestSuccessAndFail'))} />

        <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/Ticket/ListTicket'))} />
        <Route exact path={`${match.url}/get-ticket-warehouse`} component={asyncComponent(() => import('../../components/Ticket/ListTicketWareHouse'))} />
        {CheckPermission("update_ticket") && <Route exact path={`${match.url}/create-or-update/:tourist_attraction_id/:ticket_id`} component={asyncComponent(() => import('../../components/Ticket/TicketCreate'))} />}
        {CheckPermission("create_ticket") && <Route exact path={`${match.url}/create-or-update/:tourist_attraction_id`} component={asyncComponent(() => import('../../components/Ticket/TicketCreate'))} />}
        {CheckPermission("update_ticket") && <Route exact path={`${match.url}/create-or-update/of-attraction/:tourist_attraction_id/:ticket_id`} component={asyncComponent(() => import('../../components/Ticket/TicketCreate'))} />}

        {<Route exact path={`${match.url}/clone/:tourist_attraction_id/:ticket_clone_id`} component={asyncComponent(() => import('../../components/Ticket/TicketCreate'))} />}
        {CheckPermission("create_ticket") && <Route exact path={`${match.url}/create`} component={asyncComponent(() => import('../../components/Ticket/TicketCreate'))} />}
        {CheckPermission("create_ticket") && <Route exact path={`${match.url}/create/:id_attraction`} component={asyncComponent(() => import('../../components/Ticket/TicketCreate'))} />}

        <Route exact path={`${match.url}/ticket-order`} component={asyncComponent(() => import('../../components/Ticket/ListTicketOrder'))} />
        <Route exact path={`${match.url}/ticket_type/list`} component={asyncComponent(() => import('../../components/Ticket/ListTicketType'))} />
    </Switch>
)

export default Ticket;
