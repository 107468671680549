import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
const TA_Category = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/Place/ListTACategory'))} />

    </Switch>
)

export default TA_Category;