import { FetchApi } from '../../axios';
function getlistNotifyCMS(data) {
    return FetchApi('/v1.0/notify_cms', 'get', data)
}
function updateNotifyCMS(data) {
    return FetchApi('/v1.0/notify_cms', 'put', data)
}
function updateCountNotifyCMS(data) {
    return FetchApi('/v1.0/notify_cms/count_notify', 'put', data)
}
export default {
    getlistNotifyCMS,
    updateNotifyCMS,
    updateCountNotifyCMS
}