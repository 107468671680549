import { FetchApi } from '../../axios';

function getListUserTransferCredit(data) {
    return FetchApi('/v1.0/vietlook_credits', 'get', data)
}

function deleteUserTransferCredit(data) {
    return FetchApi('/v1.0/vietlook_credits', 'delete', data)
}

function createUserTransferCredit(data) {
    return FetchApi('/v1.0/vietlook_credits', 'post', data)
}

function createTransferCreditByOTPSMS(data) {
    return FetchApi('/v1.0/vietlook_credits/transfer_credit_by_otp_sms', 'post', data)
}

function accuracyOTPTransferCredit(data) {
    return FetchApi('/v1.0/vietlook_credits/accuracy_otp_transfer_credit', 'post', data)
}

function getHistoryTransferCredit(data) {
    return FetchApi('/v1.0/vietlook_credits/get_history_transfer_credit', 'get', data)
}

function getUserTransferCreditByID(data) {
    return FetchApi(`/v1.0/vietlook_credits/${data}`, 'get')
}

export default {
    getListUserTransferCredit,
    getHistoryTransferCredit,
    deleteUserTransferCredit,
    createUserTransferCredit,
    createTransferCreditByOTPSMS,
    getUserTransferCreditByID,
    accuracyOTPTransferCredit
}