import { FetchApi } from '../../axios';

function getListRequestCustomer(data) {
    return FetchApi('/v1.0/customer', 'get', data)
}

function getListReplyBefore(data) {
    return FetchApi('/v1.0/customer/reply', 'get', data)
}

function deleteRequestCustomer(data) {
    return FetchApi('/v1.0/customer', 'delete', data)
}

function createResponseCustomer(data) {
    return FetchApi('/v1.0/customer', 'post', data)
}

export default {
    getListRequestCustomer,
    deleteRequestCustomer,
    createResponseCustomer,
    getListReplyBefore
}