import { FetchApi } from '../../axios';

function getListAccountScanQr(data) {
    return FetchApi(`/v1.0/account_scan_qr`, 'get', data)
}
function createAccountScanQr(data) {
    return FetchApi('/v1.0/account_scan_qr', 'post', data)
}
function resetPass(data) {
    return FetchApi('/v1.0/account_scan_qr/reset/pass', 'put', data)
}
function updateAccountScanQr(data) {
    return FetchApi('/v1.0/account_scan_qr', 'put', data)
}
function deleteAccountScanQr(data) {
    return FetchApi('/v1.0/account_scan_qr', 'delete', data)
}
function getAccountScanQrByID(data) {
    return FetchApi(`/v1.0/account_scan_qr/${data}`, 'get')
}

function getListAccountScanQrChildren(data) {
    return FetchApi(`/v1.0/account_scan_qr/list_account_children`, 'get', data)
}
function createAccountScanQrChildren(data) {
    return FetchApi('/v1.0/account_children_scan_qr', 'post', data)
}
function updateAccountScanQrChildren(data) {
    return FetchApi('/v1.0/account_children_scan_qr', 'put', data)
}
function getListPermissionAccountScanQrByID(data) {
    return FetchApi(`/v1.0/account_children_scan_qr/${data}`, 'get')
}
function getListTicketByAccountParent(data) {
    return FetchApi(`/v1.0/account_children_scan_qr`, 'get', data)
}

export default {
    createAccountScanQr,
    updateAccountScanQr,
    getListAccountScanQr,
    resetPass,
    getAccountScanQrByID,
    deleteAccountScanQr,
    getListAccountScanQrChildren,
    createAccountScanQrChildren,
    updateAccountScanQrChildren,
    getListPermissionAccountScanQrByID,
    getListTicketByAccountParent
}