import { FetchApi } from '../../axios';

function getListHCategoryServiceRoom(data) {
    return FetchApi('/v1.0/hcategory_service_room', 'get', data)
}

function deleteHCategoryServiceRoom(data) {
    return FetchApi('/v1.0/hcategory_service_room', 'delete', data)
}

function createHCategoryServiceRoom(data) {
    return FetchApi('/v1.0/hcategory_service_room', 'post', data)
}

function updateHCategoryServiceRoom(data) {
    return FetchApi('/v1.0/hcategory_service_room', 'put', data)
}

function getHCategoryServiceRoomByID(data) {
    return FetchApi(`/v1.0/hcategory_service_room/${data}`, 'get')
}

export default {
    getListHCategoryServiceRoom,
    deleteHCategoryServiceRoom,
    createHCategoryServiceRoom,
    updateHCategoryServiceRoom,
    getHCategoryServiceRoomByID
}