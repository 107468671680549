import { FetchApi } from '../../axios';

function getSettingVersionApp() {
    return FetchApi('/v1.0/setting', 'get')
}

function updateVersionApp(data) {
    return FetchApi('/v1.0/setting', 'put', data)
}

export default {
    getSettingVersionApp,
    updateVersionApp,
}