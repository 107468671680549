const languageData = [
  {
    languageId: 'vietnamese',
    locale: 'vi',
    name: 'Vietnamese',
    icon: 'vn',
    id:1,
    key:'vn'
  },
  {
    id:2,
    languageId: 'english',
    locale: 'en',
    name: 'English (US)',
    icon: 'us',
    key:'en'
  }
];
export default languageData;
