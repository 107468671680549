import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
const Service = ({match}) => (
    <Switch>
        <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/ServiceAttraction/ListService'))} />
        {/* <Route exact path={`${match.url}/create-or-update/:tourist_attraction_id/:service_id`} component={asyncComponent(() => import('../../components/Ticket/TicketCreate'))} />
        <Route exact path={`${match.url}/create-or-update/:tourist_attraction_id`} component={asyncComponent(() => import('../../components/Ticket/TicketCreate'))} /> */}

        {/* <Route path={`${match.url}/region-province/:country_id`} component={asyncComponent(() => import('../../components/Place/RegionAndProvince'))} />
        <Route path={`${match.url}/district/:province_id/:country_id`} component={asyncComponent(() => import('../../components/Place/ListDistrict'))} />
        <Route path={`${match.url}/tourist-destination`} component={asyncComponent(() => import('../../components/Place/ListTouristDestination'))} />
        <Route path={`${match.url}/street/:district_id/:province_id/:country_id`} component={asyncComponent(() => import('../../components/Place/ListStreet'))} /> */}
    </Switch>
)

export default Service;
