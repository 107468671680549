import { FetchApiUpload, FetchApi } from '../../axios';
import { UPLOAD_DOMAIN } from '../../constants/Media'

function getListMedia(data) {
    return FetchApiUpload(`${UPLOAD_DOMAIN}/media/getList`, 'get', data)
}
function updateImage(data) {
    return FetchApiUpload(`${UPLOAD_DOMAIN}/media/update-image`, 'post', data)
}
function updateAudio(data) {
    return FetchApiUpload(`${UPLOAD_DOMAIN}/media/update-audio`, 'post', data)
}
function updateVideo(data) {
    return FetchApiUpload(`${UPLOAD_DOMAIN}/media/update-video`, 'post', data)
}
function getFolderById(data) {
    return FetchApiUpload(`${UPLOAD_DOMAIN}/folder/getFolderById`, 'get', data)
}
function createFolder(data) {
    return FetchApiUpload(`${UPLOAD_DOMAIN}/folder/create`, 'post', data)
}
function updateFolder(data) {
    return FetchApiUpload(`${UPLOAD_DOMAIN}/folder/update`, 'post', data)
}
function deleteFolder(data) {
    return FetchApiUpload(`${UPLOAD_DOMAIN}/folder/delete`, 'post', data)
}
function uploadExternalImage(data) {
    return FetchApiUpload(`${UPLOAD_DOMAIN}/media/upload-external-image`, 'post', data)
}
export default {
    getListMedia,
    updateImage,
    updateAudio,
    updateVideo,
    getFolderById,
    createFolder,
    updateFolder,
    deleteFolder,
    uploadExternalImage
}