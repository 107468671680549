import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import { CheckPermission } from "util/CheckPermission";

const VietLook_Credits = ({ match }) => (
    <Switch>
        {CheckPermission("create_account_transfer_vietlook_credit") && <Route exact path={`${match.url}/list_user_transfer_credit`} component={asyncComponent(() => import('../../components/VietLookCredits/ListUserTransferCredit'))} />}
        {CheckPermission("history_transfer_vietlook_credit") && <Route exact path={`${match.url}/history_transfer_credit`} component={asyncComponent(() => import('../../components/VietLookCredits/HistoryTransferCredit'))} />}
    </Switch>
)

export default VietLook_Credits;
