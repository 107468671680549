import { FetchApi } from '../../axios';

function getListHService(data) {
    return FetchApi('/v1.0/hservices', 'get', data)
}

function deleteHService(data) {
    return FetchApi('/v1.0/hservices', 'delete', data)
}

function createHService(data) {
    return FetchApi('/v1.0/hservices', 'post', data)
}

function updateHService(data) {
    return FetchApi('/v1.0/hservices', 'put', data)
}

function getHServiceByID(data) {
    return FetchApi(`/v1.0/hservices/${data}`, 'get')
}

export default {
    getListHService,
    deleteHService,
    createHService,
    updateHService,
    getHServiceByID
}