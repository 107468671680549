import { FetchApi } from '../../axios';

function getListPlaceAdvertis(data) {
    return FetchApi('/v1.0/placeadvertis', 'get', data)
}
function getAllListPlaceAdvertis(data) {
    return FetchApi('/v1.0/placeadvertis/list/get-list-all', 'get', data)
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createPlaceAdvertis(data) {
    return FetchApi('/v1.0/placeadvertis', 'post', data);
}
function updatePlaceAdvertis(data) {
    return FetchApi('/v1.0/placeadvertis', 'put', data)
}
function getPlaceAdvertisById(data) {
    return FetchApi(`/v1.0/placeadvertis/${data}`, 'get')
}
function deletePlaceAdvertis(data) {
    return FetchApi('/v1.0/placeadvertis', 'delete', data)

}

//Banner

function getListBannerSale(data) {
    return FetchApi('/v1.0/banner_sale', 'get', data)
}
// function getAllListPlaceAdvertis(data) {
//     return FetchApi('/v1.0/banner/list/get-list-all', 'get', data)
// }

function createBannerSale(data) {
    return FetchApi('/v1.0/banner_sale', 'post', data);
}
function updateBannerSale(data) {
    return FetchApi('/v1.0/banner_sale', 'put', data)
}
function getBannerSaleById(data) {
    return FetchApi(`/v1.0/banner_sale/${data}`, 'get')
}
function deleteBannerSale(data) {
    return FetchApi('/v1.0/banner_sale', 'delete', data)

}
export default {
    getListPlaceAdvertis,
    getAllListPlaceAdvertis,
    createPlaceAdvertis,
    updatePlaceAdvertis,
    getPlaceAdvertisById,
    deletePlaceAdvertis,
    getListBannerSale,
    createBannerSale,
    updateBannerSale,
    getBannerSaleById,
    deleteBannerSale

}