import { FetchApi } from '../../axios';

// function login(data) {
//     return FetchApi('/v1.0/ticket_agency/login', 'post', data)
// }

// function loginByToken(data) {
//     return FetchApi('/v1.0/ticket_agency/login-token', 'post', data)
// }

// function createTicketAgency(data) {
//     return FetchApi('/v1.0/ticket_agency', 'post', data)
// }

function getListTicketAgency(data) {
    return FetchApi('/v1.0/ticket_agency', 'get', data)
}
function getTicketAgencyById(data) {
    return FetchApi(`/v1.0/ticket_agency/${data}`, 'get', data)
}
// function getListPointHistory(data) {
//     return FetchApi('/v1.0/ticket_agency/get-list-point-history', 'get', data)
// }
// function updateStatusTicketAgency(data) {
//     return FetchApi('/v1.0/ticket_agency', 'put', data)
// }
// function changePassword(data) {
//     console.log("changeeepasss",data)
//     return FetchApi('/v1.0/ticket_agency/change-pass', 'put', data)
// }
// function updateTicketAgency(data) {
//     return FetchApi('/v1.0/ticket_agency/update-user', 'put', data)
// }

export default {

    getListTicketAgency,
    // updateStatusTicketAgency,
    getTicketAgencyById,
    // getListPointHistory,
    // updateTicketAgency,
    // changePassword
}