import { FetchApi } from '../../axios';

function getListHRoom(data) {
    return FetchApi('/v1.0/hroom', 'get', data)
}

function deleteHRoom(data) {
    return FetchApi('/v1.0/hroom', 'delete', data)
}

function createHRoom(data) {
    return FetchApi('/v1.0/hroom', 'post', data)
}

function updateHRoom(data) {
    return FetchApi('/v1.0/hroom', 'put', data)
}

function getHRoomByID(data) {
    return FetchApi(`/v1.0/hroom/${data}`, 'get')
}

export default {
    getListHRoom,
    deleteHRoom,
    createHRoom,
    updateHRoom,
    getHRoomByID
}