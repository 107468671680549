import { FetchApi } from '../../axios';

function getListPostCategory(data) {
    return FetchApi('/v1.0/post_category', 'get', data)
}

function deletePostCategory(data) {
    return FetchApi('/v1.0/post_category', 'delete', data)
}

function createPostCategory(data) {
    return FetchApi('/v1.0/post_category', 'post', data)
}

function updatePostCategory(data) {
    return FetchApi('/v1.0/post_category', 'put', data)
}

function getPostCategoryByID(data) {
    return FetchApi(`/v1.0/post_category/${data}`, 'get')
}

//Category Children
function getListPostCategoryChildren(category_id, data) {
    return FetchApi(`/v1.0/post_category/children/${category_id}`, 'get', data)
}

//Category All
function getListPostCategoryAll() {
    return FetchApi('/v1.0/post_category/list_category_all', 'get')
}

export default {
    getListPostCategory,
    deletePostCategory,
    createPostCategory,
    updatePostCategory,
    getPostCategoryByID,
    getListPostCategoryChildren,
    getListPostCategoryAll
}