import { FetchApi } from '../../axios';


function getListCategoryPermission(data) {
    return FetchApi('/v1.0/category_permission', 'get', data)
}
function getAllListCategoryPermission(data) {
    return FetchApi('/v1.0/category_permission/list/get-list-all', 'get', data)
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createCategoryPermission(data) {
    return FetchApi('/v1.0/category_permission', 'post', data);
}
function updateCategoryPermission(data) {
    return FetchApi('/v1.0/category_permission', 'put', data)
}
function getCategoryPermissionById(data) {
    return FetchApi(`/v1.0/category_permission/${data}`, 'get')
}
function deleteCategoryPermission(data) {
    return FetchApi('/v1.0/category_permission', 'delete', data)
}

export default {
    getListCategoryPermission,
    getAllListCategoryPermission,
    createCategoryPermission,
    updateCategoryPermission,
    getCategoryPermissionById,
    deleteCategoryPermission
}