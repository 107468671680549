import antdVN from "antd/lib/locale-provider/vi_VN";
import appLocaleData from "react-intl/locale-data/vi";
import saMessages from "../locales/VN";

const saLang = {
  messages: {
    ...saMessages
  },
  antd: antdVN,
  locale: 'vi',
  data: appLocaleData
};
export default saLang;
