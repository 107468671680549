import React, { Component } from "react";
import { Layout, Popover, Icon, Badge, Divider, Avatar, Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import RequestApi from 'apis';
import CustomScrollbars from "util/CustomScrollbars";
// import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';
import active_user from '../../../public/static/images/active_user.png';
import active_agency from '../../../public/static/images/active_agency.png';
import refund from '../../../public/static/images/refund.png';
import order from '../../../public/static/images/order.png';
import commission from '../../../public/static/images/commission.png';
// import ListUser from "../../components/User/ListUser"
// import { Route, Switch } from "react-router-dom";
import languageData from "./languageData";
import moment from "moment";
import { switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
// import SearchBox from "components/SearchBox";
// import UserInfo from "components/UserInfo";
import AppNotification from "components/AppNotification";
import MailNotification from "components/MailNotification";
import Auxiliary from "util/Auxiliary";

import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE } from "../../constants/ThemeSetting";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
const io = require("socket.io-client");
const SERVER_SOCKET = process.env.REACT_APP_SERVER_SOCKET.trim()
const socket = io(SERVER_SOCKET, {
  reconnectionDelayMax: 10000,
  // query: {
  //   auth: "123"
  // }
});
socket.on('connect', function () {
  console.log("Successfully connected socket!");
});

const { Header } = Layout;
class Topbar extends Component {
  state = {
    searchText: '',
    count: 0,
    display_notify: false,
    list_notify: [],
    paginationInfo: {
      total: 20,
      pageSize: 20,
      current: 1
    },
    hasMore: true,
    total: 0
  };

  componentDidMount = () => {
    this.getlistNotifyCMS();
    socket.emit('notify status send to server', "OK");
    socket.emit('notify send user_id to server', this.props.users ? this.props.users.id : '')
    socket.on('notify server send client', (data) => {
      this.setState({
        count: this.state.count + 1
      })
      return this.state.list_notify.unshift(data.new_notify)
    })
  }

  getlistNotifyCMS = () => {
    let { paginationInfo } = this.state;
    RequestApi.getlistNotifyCMS({ page: paginationInfo.current, size: paginationInfo.pageSize }).then(res => {
      if (res.code) {
        this.setState({
          count: res.data.count_notify,
          list_notify: res.data.rows,
          total: res.data.count
        })
      }
    })
  }

  getLoadNotifyCMS = async (notify_id) => {
    await RequestApi.updateNotifyCMS({ notify_id }).then(res => {
      if (res.data) {
        return
      }
    })

    await RequestApi.getlistNotifyCMS({ page: 1, size: 20 }).then(res => {
      if (res.code) {
        this.setState({
          // count: res.data.count_notify,
          list_notify: res.data.rows,
          total: res.data.count,
          paginationInfo: {
            total: 20,
            pageSize: 20,
            current: 1
          },
          count: 0
        })
      }
    })
  }

  resetnotify = async () => {
    let { count } = this.state;
    if (count != 0) {
      this.setState({
        count: 0
      })

      await RequestApi.updateCountNotifyCMS().then(res => {
        if (res.data) {
          return
        }
      })
    }
  }

  timeSinceBefore = (date) => {
    date = new Date(date);
    var seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval >= 1) {
      return interval + ' năm';
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return interval + ' tháng';
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return interval + ' ngày';
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return interval + ' giờ';
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval + ' phút';
    }
    return Math.floor(seconds) + ' giây';
  }

  fetchMoreData = async () => {
    let { paginationInfo, total, list_notify } = this.state;
    if ((paginationInfo.current * paginationInfo.pageSize) >= total) {
      this.setState({ hasMore: false });
    }

    await RequestApi.getlistNotifyCMS({ page: paginationInfo.current + 1, size: paginationInfo.pageSize }).then(res => {
      if (res.code) {
        this.setState({
          count: res.data.count_notify,
          list_notify: list_notify.concat(res.data.rows),
          total: res.data.count,
          paginationInfo: {
            total: 20 * (paginationInfo.current + 1),
            pageSize: 20,
            current: paginationInfo.current + 1
          }
        })
      }
    })
  };

  openNotification = (list_notify) => {
    return (
      // <CustomScrollbars className="gx-popover-scroll">
      <div style={{ width: 310 }}>
        <h3 style={{ marginLeft: 10 }}><b>Thông báo</b></h3>
        <Divider />
        <InfiniteScroll
          dataLength={this.state.paginationInfo.total}
          next={this.fetchMoreData}
          hasMore={this.state.hasMore}
          loader={<h4>Loading...</h4>}
          height={300}
        >
          <ul className="gx-sub-popover">
            {
              list_notify && list_notify.map(item => {
                if (item.active == false) {
                  switch (item.type) {
                    case 'USER':
                      return <a onClick={() => this.getLoadNotifyCMS(item.id)}><NavLink to={`/user/list/${item.metadata.user_id}`}>
                        <Avatar src={active_user} style={{ marginLeft: -5 }}
                          className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                        <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170 }}>Xác nhận tài khoản</p>
                        {item.type == 'USER' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "black" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b> đã yêu cầu xác nhận tài khoản</p>}
                        <span className="circle-notify" style={{ fontSize: 15, float: "right", marginTop: -53 }}></span>
                        <h6 style={{ fontSize: 10, float: "right", marginTop: -35 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                      </NavLink></a>
                    case 'REFUND_REQUEST':
                      return <a onClick={() => this.getLoadNotifyCMS(item.id)}><NavLink to={`/refund/list/${item.metadata.refund_id}`}>
                        <Avatar src={refund} style={{ marginLeft: -5 }}
                          className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                        <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170 }}>Yêu cầu hoàn tiền</p>
                        {item.type == 'REFUND_REQUEST' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "black" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã yêu cầu hoàn tiền</p>}
                        <span className="circle-notify" style={{ fontSize: 15, float: "right", marginTop: -42 }}></span>
                        <h6 style={{ fontSize: 10, float: "right", marginTop: -24 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                      </NavLink></a>
                    case 'TICKET_AGENCY':
                      return <a onClick={() => this.getLoadNotifyCMS(item.id)}><NavLink to={`/ticket_agency/list/${item.metadata.user_id}`}>
                        <Avatar src={active_agency} style={{ marginLeft: -5 }}
                          className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                        <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170 }}>Xác nhận đại lý</p>
                        {item.type == 'TICKET_AGENCY' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "black" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã yêu cầu xác nhận đại lý</p>}
                        <span className="circle-notify" style={{ fontSize: 15, float: "right", marginTop: -42 }}></span>
                        <h6 style={{ fontSize: 10, float: "right", marginTop: -24 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                      </NavLink></a>
                    case 'ORDER':
                      return <a onClick={() => this.getLoadNotifyCMS(item.id)}><NavLink to={`/order/ticket_order_booking/list/${item.metadata.order_id}`}>
                        <Avatar src={order} style={{ marginLeft: -5 }}
                          className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                        <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170 }}>Đơn hàng</p>
                        {item.type == 'ORDER' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "black" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã đặt hàng</p>}
                        <span className="circle-notify" style={{ fontSize: 15, float: "right", marginTop: -40 }}></span>
                        <h6 style={{ fontSize: 10, float: "right", marginTop: -22 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                      </NavLink></a>
                    case 'NOTIFY_COMMISSION':
                      return <a onClick={() => this.getLoadNotifyCMS(item.id)}><NavLink to={`/refund_rose_request/list/${item.metadata.rose_request_id}`}>
                        <Avatar src={commission} style={{ marginLeft: -5 }}
                          className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                        <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170 }}>Thanh toán hoa hồng</p>
                        {item.type == 'NOTIFY_COMMISSION' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "black" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã yêu cầu thanh toán hoa hồng</p>}
                        <span className="circle-notify" style={{ fontSize: 15, float: "right", marginTop: -53 }}></span>
                        <h6 style={{ fontSize: 10, float: "right", marginTop: -35 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                      </NavLink></a>

                    case 'REQUEST_DEPOSIT_CART':
                      return <a onClick={() => this.getLoadNotifyCMS(item.id)}>
                        <NavLink to={`/request_deposit_cart/list/${item.metadata.request_deposit_cart_id}`}>
                        <Avatar src={order} style={{ marginLeft: -5 }}
                          className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                        <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170 }}>Nạp thẻ điện thoại</p>
                        {item.type == 'REQUEST_DEPOSIT_CART' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "black" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã yêu cầu nạp thẻ</p>}
                        <span className="circle-notify" style={{ fontSize: 15, float: "right", marginTop: -40 }}></span>
                        <h6 style={{ fontSize: 10, float: "right", marginTop: -22 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                        </NavLink>
                      </a>
                  }
                  // return (
                  //   <div>
                  //     {item.type == 'USER' && <a onClick={() => this.getLoadNotifyCMS(item.id)}><NavLink to={`/user/list/${item.metadata.user_id}`}>
                  //       <Avatar src={active_user} style={{ marginLeft: -5 }}
                  //         className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                  //       <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170 }}>Xác nhận tài khoản</p>
                  //       {item.type == 'USER' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "black" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b> đã yêu cầu xác nhận tài khoản</p>}
                  //       <span className="circle-notify" style={{ fontSize: 15, float: "right", marginTop: -53 }}></span>
                  //       <h6 style={{ fontSize: 10, float: "right", marginTop: -35 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                  //     </NavLink></a>}
                  //     {item.type == 'REFUND_REQUEST' && <a onClick={() => this.getLoadNotifyCMS(item.id)}><NavLink to={`/refund/list/${item.metadata.refund_id}`}>
                  //       <Avatar src={refund} style={{ marginLeft: -5 }}
                  //         className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                  //       <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170 }}>Yêu cầu hoàn tiền</p>
                  //       {item.type == 'REFUND_REQUEST' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "black" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã yêu cầu hoàn tiền</p>}
                  //       <span className="circle-notify" style={{ fontSize: 15, float: "right", marginTop: -42 }}></span>
                  //       <h6 style={{ fontSize: 10, float: "right", marginTop: -24 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                  //     </NavLink></a>}
                  //     {item.type == 'TICKET_AGENCY' && <a onClick={() => this.getLoadNotifyCMS(item.id)}><NavLink to={`/ticket_agency/list/${item.metadata.user_id}`}>
                  //       <Avatar src={active_agency} style={{ marginLeft: -5 }}
                  //         className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                  //       <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170 }}>Xác nhận đại lý</p>
                  //       {item.type == 'TICKET_AGENCY' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "black" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã yêu cầu xác nhận đại lý</p>}
                  //       <span className="circle-notify" style={{ fontSize: 15, float: "right", marginTop: -42 }}></span>
                  //       <h6 style={{ fontSize: 10, float: "right", marginTop: -24 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                  //     </NavLink></a>}
                  //     {item.type == 'ORDER' && <a onClick={() => this.getLoadNotifyCMS(item.id)}><NavLink to={`/order/ticket_order_booking/list/${item.metadata.order_id}`}>
                  //       <Avatar src={order} style={{ marginLeft: -5 }}
                  //         className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                  //       <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170 }}>Đơn hàng</p>
                  //       {item.type == 'ORDER' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "black" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã đặt hàng</p>}
                  //       <span className="circle-notify" style={{ fontSize: 15, float: "right", marginTop: -40 }}></span>
                  //       <h6 style={{ fontSize: 10, float: "right", marginTop: -22 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                  //     </NavLink></a>}
                  //     {/* //Tiền hoa hồng */}
                  //     {item.type == 'NOTIFY_COMMISSION' && <a onClick={() => this.getLoadNotifyCMS(item.id)}><NavLink to={`/refund_rose_request/list/${item.metadata.rose_request_id}`}>
                  //       <Avatar src={commission} style={{ marginLeft: -5 }}
                  //         className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                  //       <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170 }}>Thanh toán hoa hồng</p>
                  //       {item.type == 'NOTIFY_COMMISSION' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "black" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã yêu cầu thanh toán hoa hồng</p>}
                  //       <span className="circle-notify" style={{ fontSize: 15, float: "right", marginTop: -53 }}></span>
                  //       <h6 style={{ fontSize: 10, float: "right", marginTop: -35 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                  //     </NavLink></a>}
                  //   </div>
                  // )
                }
                else {
                  switch (item.type) {
                    case 'USER':
                      return <NavLink to={`/user/list/${item.metadata.user_id}`}>
                        <Avatar src={active_user} style={{ marginLeft: -5 }}
                          className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                        <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}>Xác nhận tài khoản</p>
                        {item.type == 'USER' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b> đã yêu cầu xác nhận tài khoản</p>}
                        <h6 style={{ fontSize: 10, float: "right", marginTop: -35 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                      </NavLink>
                    case 'REFUND_REQUEST':
                      return <NavLink to={`/refund/list/${item.metadata.refund_id}`}>
                        <Avatar src={refund} style={{ marginLeft: -5 }}
                          className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                        <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}>Yêu cầu hoàn tiền</p>
                        {item.type == 'REFUND_REQUEST' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã yêu cầu hoàn tiền</p>}
                        <h6 style={{ fontSize: 10, float: "right", marginTop: -35 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                      </NavLink>
                    case 'TICKET_AGENCY':
                      return <NavLink to={`/ticket_agency/list/${item.metadata.user_id}`}>
                        <Avatar src={active_agency} style={{ marginLeft: -5 }}
                          className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                        <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}>Xác nhận đại lý</p>
                        {item.type == 'TICKET_AGENCY' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã yêu cầu xác nhận đại lý</p>}
                        <h6 style={{ fontSize: 10, float: "right", marginTop: -35 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                      </NavLink>
                    case 'ORDER':
                      return <NavLink to={`/order/ticket_order_booking/list/${item.metadata.order_id}`}>
                        <Avatar src={order} style={{ marginLeft: -5 }}
                          className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                        <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}>Đơn hàng</p>
                        {item.type == 'ORDER' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã đặt hàng</p>}
                        <h6 style={{ fontSize: 10, float: "right", marginTop: -24 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                      </NavLink>
                    case 'NOTIFY_COMMISSION':
                      return <NavLink to={`/refund_rose_request/list/${item.metadata.rose_request_id}`}>
                        <Avatar src={commission} style={{ marginLeft: -5 }}
                          className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                        <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}>Thanh toán hoa hồng</p>
                        {item.type == 'NOTIFY_COMMISSION' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã yêu cầu thanh toán hoa hồng</p>}
                        <h6 style={{ fontSize: 10, float: "right", marginTop: -35 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                      </NavLink>

                    case 'REQUEST_DEPOSIT_CART':
                      return (
                          <NavLink to={`/request_deposit_cart/list/${item.metadata.request_deposit_cart_id}`}>
                            <Avatar src={order} style={{ marginLeft: -5 }}
                              className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                            <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}>Đơn hàng</p>
                            {item.type == 'REQUEST_DEPOSIT_CART' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã đặt hàng</p>}
                            <h6 style={{ fontSize: 10, float: "right", marginTop: -24 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                          </NavLink>

                      )

                  }
                  // return (
                  //   <div>
                  //     {item.type == 'USER' && <NavLink to={`/user/list/${item.metadata.user_id}`}>
                  //       <Avatar src={active_user} style={{ marginLeft: -5 }}
                  //         className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                  //       <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}>Xác nhận tài khoản</p>
                  //       {item.type == 'USER' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b> đã yêu cầu xác nhận tài khoản</p>}
                  //       <h6 style={{ fontSize: 10, float: "right", marginTop: -35 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                  //     </NavLink>}
                  //     {item.type == 'REFUND_REQUEST' && <NavLink to={`/refund/list/${item.metadata.refund_id}`}>
                  //       <Avatar src={refund} style={{ marginLeft: -5 }}
                  //         className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                  //       <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}>Yêu cầu hoàn tiền</p>
                  //       {item.type == 'REFUND_REQUEST' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã yêu cầu hoàn tiền</p>}
                  //       <h6 style={{ fontSize: 10, float: "right", marginTop: -35 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                  //     </NavLink>}
                  //     {item.type == 'TICKET_AGENCY' && <NavLink to={`/ticket_agency/list/${item.metadata.user_id}`}>
                  //       <Avatar src={active_agency} style={{ marginLeft: -5 }}
                  //         className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                  //       <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}>Xác nhận đại lý</p>
                  //       {item.type == 'TICKET_AGENCY' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã yêu cầu xác nhận đại lý</p>}
                  //       <h6 style={{ fontSize: 10, float: "right", marginTop: -35 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                  //     </NavLink>}
                  //     {item.type == 'ORDER' && <NavLink to={`/order/ticket_order_booking/list/${item.metadata.order_id}`}>
                  //       <Avatar src={order} style={{ marginLeft: -5 }}
                  //         className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                  //       <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}>Đơn hàng</p>
                  //       {item.type == 'ORDER' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã đặt hàng</p>}
                  //       <h6 style={{ fontSize: 10, float: "right", marginTop: -24 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                  //     </NavLink>}

                  //     {item.type == 'NOTIFY_COMMISSION' && <NavLink to={`/refund_rose_request/list/${item.metadata.rose_request_id}`}>
                  //       <Avatar src={commission} style={{ marginLeft: -5 }}
                  //         className="gx-size-39 gx-pointer gx-mr-2" alt="" />
                  //       <p style={{ fontSize: 14, marginTop: -33, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}>Thanh toán hoa hồng</p>
                  //       {item.type == 'NOTIFY_COMMISSION' && <p style={{ fontSize: 10, marginTop: -10, marginLeft: 40, textAlign: "left", width: 170, color: "grey" }}><b style={{ fontSize: 12 }}>{item && item.name || item.fullname || item.email || item.phone} </b>đã yêu cầu thanh toán hoa hồng</p>}
                  //       <h6 style={{ fontSize: 10, float: "right", marginTop: -35 }}>{this.timeSinceBefore(moment(item.createdAt))}</h6>
                  //     </NavLink>}
                  //   </div>
                  // )
                }
              })}
          </ul>
        </InfiniteScroll>
      </div>
    );
  }

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            this.props.switchLanguage(language)
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>
  );

  updateSearchChatUser = (evt) => {
    this.setState({
      searchText: evt.target.value,
    });
  };

  render() {
    const { locale, width, navCollapsed, navStyle } = this.props;
    let { list_notify } = this.state
    return (
      <Auxiliary>
        <Header>
          {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
            <div className="gx-linebar gx-mr-3">
              <i className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  this.props.toggleCollapsedSideNav(!navCollapsed);
                }}
              />
            </div> : null}
          <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
            <img alt="" src={require("assets/images/logo-color.png")} /></Link>

          {/* <SearchBox styleName="gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg"
            placeholder="Search in app..."
            onChange={this.updateSearchChatUser.bind(this)}
            value={this.state.searchText} /> */}

          <ul className="gx-header-notifications gx-ml-auto">
            {/* <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={
                <SearchBox styleName="gx-popover-search-bar"
                  placeholder="Search in app..."
                  onChange={this.updateSearchChatUser.bind(this)}
                  value={this.state.searchText} />
              } trigger="click">
                <span className="gx-pointer gx-d-block"><i className="icon icon-search-new" /></span>
              </Popover>
            </li> */}
            {width >= TAB_SIZE ? null :
              <Auxiliary>
                <li className="gx-notify">
                  <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification />}
                    trigger="click">
                    <span className="gx-pointer gx-d-block"><i className="icon icon-notification" /></span>
                  </Popover>
                </li>
                <li className="gx-msg">
                  <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
                    content={<MailNotification />} trigger="click">
                    <span className="gx-pointer gx-status-pos gx-d-block">
                      <i className="icon icon-chat-new" />
                      <span className="gx-status gx-status-rtl gx-small gx-orange" />
                    </span>
                  </Popover>
                </li>
              </Auxiliary>
            }
            <li>
              <div style={{ marginTop: 6 }}>
                <Popover className="gx-popover-horizantal" placement="bottom" content={this.openNotification(list_notify)}
                  trigger="click">
                  <Badge count={this.state.count}>
                    <a className="head-example" onClick={() => this.resetnotify()}>
                      {/* <Icon style={{ float: 'right', fontSize: 25 }} type="bell" /> */}
                      <i className="icon icon-notification" style={{ fontSize: 20 }}></i>
                    </a>
                  </Badge>
                </Popover>
              </div>
            </li>
            <li className="gx-language">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={this.languageMenu()}
                trigger="click">
                <span className="gx-pointer gx-flex-row gx-align-items-center">
                  <i className={`flag flag-24 flag-${locale.icon}`} />
                  <span className="gx-pl-2 gx-language-name">{locale.name}</span>
                  <i className="icon icon-chevron-down gx-pl-2" />
                </span>
              </Popover>
            </li>
            {/* {width >= TAB_SIZE ? null :
              <Auxiliary>
                <li className="gx-user-nav"><UserInfo /></li>
              </Auxiliary>
            } */}
          </ul>
        </Header>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale, navStyle, navCollapsed, width } = settings;
  const users = auth.profile;
  return { locale, navStyle, navCollapsed, width, users }
};

export default connect(mapStateToProps, { toggleCollapsedSideNav, switchLanguage })(Topbar);