import { FetchApi } from '../../axios';

function getListHServiceRoom(data) {
    return FetchApi('/v1.0/hservice_room', 'get', data)
}

function deleteHServiceRoom(data) {
    return FetchApi('/v1.0/hservice_room', 'delete', data)
}

function createHServiceRoom(data) {
    return FetchApi('/v1.0/hservice_room', 'post', data)
}

function updateHServiceRoom(data) {
    return FetchApi('/v1.0/hservice_room', 'put', data)
}

function getHServiceRoomByID(data) {
    return FetchApi(`/v1.0/hservice_room/${data}`, 'get')
}

export default {
    getListHServiceRoom,
    deleteHServiceRoom,
    createHServiceRoom,
    updateHServiceRoom,
    getHServiceRoomByID
}