import { FetchApi } from "../../axios";

function getListTourCategory(data) {
    return FetchApi("/v1.0/tour_category", "get", data);
}
function getAllListTourCategory(data) {
    return FetchApi("/v1.0/tour_category/list/get-list-all", "get", data);
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createTourCategory(data) {
    return FetchApi("/v1.0/tour_category", "post", data);
}
function updateTourCategory(data) {
    return FetchApi("/v1.0/tour_category", "put", data);
}
function getTourCategoryById(data) {
    return FetchApi(`/v1.0/tour_category/${data}`, "get");
}
function deleteTourCategory(data) {
    return FetchApi("/v1.0/tour_category", "delete", data);
}

export default {
    getListTourCategory,
    getAllListTourCategory,
    createTourCategory,
    updateTourCategory,
    getTourCategoryById,
    deleteTourCategory
};
