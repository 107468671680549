import { FetchApi } from '../../axios';


function getListStatusMailGun(data) {
    return FetchApi('/v1.0/status_mail_gun', 'get', data)
}
function createStatusMailGun(data) {
    return FetchApi('/v1.0/status_mail_gun/create', 'post', data)
}


// function getAllTransaction(data) {
//     return FetchApi('/v1.0/transaction/all', 'get', data)
// }

export default {
    getListStatusMailGun,
    createStatusMailGun
}