import { FetchApi } from '../../axios';


function getListStoreCategory(data) {
    return FetchApi('/v1.0/store_category', 'get', data)
}
function getAllListStoreCategory(data) {
    return FetchApi('/v1.0/store_category/list/get-list-all', 'get', data)
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createStoreCategory(data) {
    return FetchApi('/v1.0/store_category', 'post', data);
}
function updateStoreCategory(data) {
    return FetchApi('/v1.0/store_category', 'put', data)
}
function getStoreCategoryById(data) {
    return FetchApi(`/v1.0/store_category/${data}`, 'get')
}
function deleteStoreCategory(data) {
    return FetchApi('/v1.0/store_category', 'delete', data)
}

export default {
    getListStoreCategory,
    getAllListStoreCategory,
    createStoreCategory,
    updateStoreCategory,
    getStoreCategoryById,
    deleteStoreCategory
}