import users from './users';
import ticket_agency from './ticket_agency';
import area from './area';
import tickets from './tickets';
import account_scan_qr from './account_scan_qr';
import tour from './tour';
import partner from './partner';
// import service from './service';
import ticket_group from './ticket_group';
import status_mail_gun from './status_mail_gun';
import ticket_price_max_day from './ticket_price_max_day'
import rating from './rating';
import countrys from './countrys';
import languages from './languages';
import regions from './regions';
import provinces from './provinces';
import post from './post';
import post_category from './post_category';
import ta_category from './ta_category';
import refund from './refund';
import refund_rose_request from './refund_rose_request';
import request_deposit_cart from './request_deposit_cart';
import category_permission from './category_permission';
import role from './role';
import notify_cms from './notify_cms';
import permission from './permission';
import restaurant_category from './restaurant_category';
import tour_category from './tour_category';
import store_category from './store_category';
import tourist_destination from './tourist_destination';
import hotels from './hotels';
import hcategoryservices from './hcategoryservices';
import services from './hservices';
import hcategoryrooms from './hcategoryrooms';
import hcategorybeds from './hcategorybeds';
import hcategory_service_room from './hcategory_service_room';
import hservice_room from './hservice_room';
import hroom from './hroom';
import hdirections from './hdirections';
import restaurant from "./restaurant";
import store from "./store";
import move from "./move";
import district from "./district";
import streets from "./streets";
import place from "./place";
import banner from "./banner";
import banner_sale from "./banner_sale";
import media from "./media";
import customer from "./customer";
import transaction from './transaction';
import vietlook_credits from './vietlook_credits';
import setting from './setting';
import dashboard from './dashboard'
let api = {
    ...users,
    ...ticket_agency,
    ...category_permission,
    ...request_deposit_cart,
    ...rating,
    ...area,
    ...tickets,
    ...status_mail_gun,
    ...role,
    ...notify_cms,
    ...permission,
    ...ta_category,
    ...refund,
    ...refund_rose_request,
    ...restaurant_category,
    ...tour_category,
    ...store_category,
    ...partner,
    ...account_scan_qr,
    // ...service,
    ...countrys,
    ...languages,
    ...regions,
    ...ticket_group,
    ...ticket_price_max_day,
    ...provinces,
    ...post,
    ...post_category,
    ...district,
    ...tourist_destination,
    ...hotels,
    ...hcategoryservices,
    ...services,
    ...hcategoryrooms,
    ...hcategorybeds,
    ...hcategory_service_room,
    ...hservice_room,
    ...hroom,
    ...hdirections,
    ...restaurant,
    ...store,
    ...move,
    ...streets,
    ...tour,
    ...place,
    ...banner,
    ...banner_sale,
    ...media,
    ...customer,
    ...transaction,
    ...vietlook_credits,
    ...setting,
    ...dashboard
}

export default api;