import { FetchApi } from '../../axios';

function getListHCategoryBed(data) {
    return FetchApi('/v1.0/hcategory_bed', 'get', data)
}

function deleteHCategoryBed(data) {
    return FetchApi('/v1.0/hcategory_bed', 'delete', data)
}

function createHCategoryBed(data) {
    return FetchApi('/v1.0/hcategory_bed', 'post', data)
}

function updateHCategoryBed(data) {
    return FetchApi('/v1.0/hcategory_bed', 'put', data)
}

function getHCategoryBedByID(data) {
    return FetchApi(`/v1.0/hcategory_bed/${data}`, 'get')
}

export default {
    getListHCategoryBed,
    deleteHCategoryBed,
    createHCategoryBed,
    updateHCategoryBed,
    getHCategoryBedByID
}