import { FetchApi } from "../../axios";

function getListRestaurantCategory(data) {
  return FetchApi("/v1.0/restaurant_category", "get", data);
}
function getAllListRestaurantCategory(data) {
  return FetchApi("/v1.0/restaurant_category/list/get-list-all", "get", data);
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createRestaurantCategory(data) {
  return FetchApi("/v1.0/restaurant_category", "post", data);
}
function updateRestaurantCategory(data) {
  return FetchApi("/v1.0/restaurant_category", "put", data);
}
function getRestaurantCategoryById(data) {
  return FetchApi(`/v1.0/restaurant_category/${data}`, "get");
}
function deleteRestaurantCategory(data) {
  return FetchApi("/v1.0/restaurant_category", "delete", data);
}

export default {
  getListRestaurantCategory,
  getAllListRestaurantCategory,
  createRestaurantCategory,
  updateRestaurantCategory,
  getRestaurantCategoryById,
  deleteRestaurantCategory
};
