import { FetchApi } from "../../axios";

function createTour(data) {
    return FetchApi("/v1.0/tour/create", "post", data);
}

function getTourList(data) {
    return FetchApi("/v1.0/tour/list", "get", data);
}
function getAllTour(data) {
    return FetchApi("/v1.0/tour/list/all", "get", data);
}

function getTourByID(data) {
    return FetchApi("/v1.0/tour/get-infor", "get", data);
}
function getNameTourByID(data) {
    return FetchApi("/v1.0/tour/get-infor/get-name", "get", data);
}

function updateTour(data) {
    return FetchApi("/v1.0/tour/update", "put", data);
}

function deleteTour(data) {
    return FetchApi("/v1.0/tour/del", "delete", data);
}

function getAllDestinations(data) {
    return FetchApi("/v1.0/tour/get-all-destinations", "get", data);
}
//Category menu
// function getListMenu(data) {
//     return FetchApi('/v1.0/rmenu', 'get', data);
// }
function getListCategoryMenu(data) {
    return FetchApi("/v1.0/rcategorymenu", "get", data);
}
function getAllListCategoryMenu(data) {
    return FetchApi("/v1.0/rcategorymenu/list/get-list-all", "get", data);
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createCategoryMenu(data) {
    return FetchApi("/v1.0/rcategorymenu", "post", data);
}
function updateCategoryMenu(data) {
    return FetchApi("/v1.0/rcategorymenu", "put", data);
}
function getCategoryMenuById(data) {
    return FetchApi(`/v1.0/rcategorymenu/${data}`, "get");
}
function deleteCategoryMenu(data) {
    return FetchApi("/v1.0/rcategorymenu", "delete", data);
}

//api menu
function getListMenu(data) {
    return FetchApi("/v1.0/rmenu", "get", data);
}
function getAllListMenu(data) {
    return FetchApi("/v1.0/rmenu/list/get-list-all", "get", data);
}
function getFoodOfMenu(data) {
    return FetchApi("/v1.0/rmenu/list/get-food-of-menu", "get", data);
}
function createMenu(data) {
    return FetchApi("/v1.0/rmenu", "post", data);
}
function updateMenu(data) {
    return FetchApi("/v1.0/rmenu", "put", data);
}
function getMenuById(data) {
    return FetchApi(`/v1.0/rmenu/${data}`, "get");
}
function deleteMenu(data) {
    return FetchApi("/v1.0/rmenu", "delete", data);
}

//api food
function getListFood(data) {
    return FetchApi("/v1.0/rfood", "get", data);
}
function getAllListFood(data) {
    return FetchApi("/v1.0/rfood/list/get-list-all", "get", data);
}
function createFood(data) {
    return FetchApi("/v1.0/rfood", "post", data);
}
function updateFood(data) {
    return FetchApi("/v1.0/rfood", "put", data);
}
function getFoodById(data) {
    return FetchApi(`/v1.0/rfood/${data}`, "get");
}
function deleteFood(data) {
    return FetchApi("/v1.0/rfood", "delete", data);
}
function deleteFoodOfMenu(data) {
    return FetchApi("/v1.0/rfood/food-of-menu", "delete", data);
}

export default {
    createTour,
    getTourList,
    getAllTour,
    getTourByID,
    getNameTourByID,
    updateTour,
    deleteTour,
    getAllDestinations,
    getListMenu,
    getAllListMenu,
    createMenu,
    updateMenu,
    getFoodOfMenu,
    getMenuById,
    deleteMenu,
    getListFood,
    getAllListFood,
    createFood,
    updateFood,
    getFoodById,
    deleteFood,
    getListCategoryMenu,
    getAllListCategoryMenu,
    createCategoryMenu,
    updateCategoryMenu,
    getCategoryMenuById,
    deleteCategoryMenu,
    deleteFoodOfMenu
};
