import { FetchApi } from '../../axios';

function getListHotel(data) {
    return FetchApi('/v1.0/hotels', 'get', data)
}

function deleteHotel(data) {
    return FetchApi('/v1.0/hotels', 'delete', data)
}

function createHotel(data) {
    return FetchApi('/v1.0/hotels', 'post', data)
}

function updateHotel(data) {
    return FetchApi('/v1.0/hotels', 'put', data)
}

function getHotelByID(data) {
    return FetchApi(`/v1.0/hotels/${data}`, 'get')
}

function getHotelActive(data) {
    return FetchApi(`/v1.0/hotels/active`, 'get',data)
}
export default{
    getListHotel,
    deleteHotel,
    createHotel,
    updateHotel,
    getHotelByID,
    getHotelActive
}