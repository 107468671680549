import React from "react";
import { Route, Switch } from "react-router-dom";
import { CheckPermission } from "util/CheckPermission";
import asyncComponent from "util/asyncComponent";

const Setting = ({ match }) => (

    <Switch>
        {CheckPermission("admin") && <Route exact path={`${match.url}/version-app`} component={asyncComponent(() => import('../../components/Setting/VersionApp'))} />}
        {/* {CheckPermission("admin") && <Route exact path={`${match.url}/admin/list`} component={asyncComponent(() => import('../../components/Role/ListAdmin'))} />} */}
    </Switch>
)

export default Setting;