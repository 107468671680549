import { FetchApi } from '../../axios';


function getPlaceArea(data) {
    return FetchApi('/v1.0/place/area', 'get', data)
}

function getPlaceCountry(data) {
    return FetchApi('/v1.0/place/country', 'get', data)
}

function getPlaceRegion(data) {
    return FetchApi('/v1.0/place/region', 'get', data)
}

function getPlaceProvince(data) {
    return FetchApi('/v1.0/place/province', 'get', data)
}

function getPlaceDistrict(data) {
    return FetchApi('/v1.0/place/district', 'get', data)
}

function getPlaceStreet(data) {
    return FetchApi('/v1.0/place/street', 'get', data)
}


function getListTouristDestinations(data) {
    return FetchApi('/v1.0/place/tourist-destination', 'get', data)
}

export default {
    getPlaceArea,
    getPlaceCountry,
    getPlaceRegion,
    getPlaceProvince,
    getPlaceStreet,
    getPlaceDistrict,
    getListTouristDestinations
}