import { FetchApi } from '../../axios';

function createStore(data) {
    return FetchApi('/v1.0/store/create', 'post', data);
}

function getListStore(data) {
    return FetchApi('/v1.0/store/list', 'get', data);
}
function getAllStore(data) {
    return FetchApi('/v1.0/store/list/all', 'get', data);
}

function getStoreByID(data) {
    return FetchApi('/v1.0/store/get-infor', 'get', data);
}
function getNameStoreByID(data) {
    return FetchApi('/v1.0/store/get-infor/get-name', 'get', data);
}

function updateStore(data) {
    return FetchApi('/v1.0/store/update', 'put', data);
}

function deleteStore(data) {
    return FetchApi('/v1.0/store/del', 'delete', data);
}

function getAllDestinations(data) {
    return FetchApi('/v1.0/store/get-all-destinations', 'get', data)
}
//Category menu
// function getListMenu(data) {
//     return FetchApi('/v1.0/store_catalogue', 'get', data);
// }
// function getListCategoryMenu(data) {
//     return FetchApi('/v1.0/rcategorymenu', 'get', data)
// }
// function getAllListCategoryMenu(data) {
//     return FetchApi('/v1.0/rcategorymenu/list/get-list-all', 'get', data)
// }
// // function getStoreCatalogueItemOfMenu(data) {
// //     return FetchApi('/v1.0/store_catalogue/list/get-food-of-menu', 'get', data)
// // }
// function createCategoryMenu(data) {
//     return FetchApi('/v1.0/rcategorymenu', 'post', data);
// }
// function updateCategoryMenu(data) {
//     return FetchApi('/v1.0/rcategorymenu', 'put', data)
// }
// function getCategoryMenuById(data) {
//     return FetchApi(`/v1.0/rcategorymenu/${data}`, 'get')
// }
// function deleteCategoryMenu(data) {
//     return FetchApi('/v1.0/rcategorymenu', 'delete', data)
// }


//api menu
function getListStoreCatalogue(data) {
    return FetchApi('/v1.0/store_catalogue', 'get', data);
}
function getAllListStoreCatalogue(data) {
    return FetchApi('/v1.0/store_catalogue/list/get-list-all', 'get', data)
}
function getStoreCatalogueItemOfMenu(data) {
    return FetchApi('/v1.0/store_catalogue/list/get-item-of-catalogue', 'get', data)
}
function createStoreCatalogue(data) {
    return FetchApi('/v1.0/store_catalogue', 'post', data);
}
function updateStoreCatalogue(data) {
    return FetchApi('/v1.0/store_catalogue', 'put', data)
}
function getStoreCatalogueById(data) {
    return FetchApi(`/v1.0/store_catalogue/${data}`, 'get')
}
function deleteStoreCatalogue(data) {
    return FetchApi('/v1.0/store_catalogue', 'delete', data)
}




// //api food
function getListStoreCatalogueItem(data) {
    return FetchApi('/v1.0/store_catalogue_item', 'get', data);
}
function getAllListStoreCatalogueItem(data) {
    return FetchApi('/v1.0/store_catalogue_item/list/get-list-all', 'get', data)
}
function createStoreCatalogueItem(data) {
    return FetchApi('/v1.0/store_catalogue_item', 'post', data);
}
function updateStoreCatalogueItem(data) {
    return FetchApi('/v1.0/store_catalogue_item', 'put', data)
}
function getStoreCatalogueItemById(data) {
    return FetchApi(`/v1.0/store_catalogue_item/${data}`, 'get')
}
function deleteStoreCatalogueItem(data) {
    return FetchApi('/v1.0/store_catalogue_item', 'delete', data)
}
function deleteStoreCatalogueItemOfMenu(data) {
    return FetchApi('/v1.0/store_catalogue_item/item-of-catalogue', 'delete', data)
}


export default {
    createStore,
    getListStore,
    getAllStore,
    getStoreByID,
    getNameStoreByID,
    updateStore,
    deleteStore,
    getAllDestinations,
    getListStoreCatalogue,
    getAllListStoreCatalogue,
    createStoreCatalogue,
    updateStoreCatalogue,
    getStoreCatalogueItemOfMenu,
    getStoreCatalogueById,
    deleteStoreCatalogue,
    getListStoreCatalogueItem,
    getAllListStoreCatalogueItem,
    createStoreCatalogueItem,
    updateStoreCatalogueItem,
    getStoreCatalogueItemById,
    deleteStoreCatalogueItem,
    // getListCategoryMenu,
    // getAllListCategoryMenu,
    // createCategoryMenu,
    // updateCategoryMenu,
    // getCategoryMenuById,
    // deleteCategoryMenu,
    deleteStoreCatalogueItemOfMenu



}