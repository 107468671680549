import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNIN_USER_BY_TOKEN
} from "constants/ActionTypes";
import { showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignInByTokenSuccess, loadAuthen } from "../../appRedux/actions/Auth";
import CallApi from 'apis';
const signInUserWithEmailPasswordRequest = async (email, password) =>
  await CallApi.login({ email, password })
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithTokenRequest = async (token) =>
  await CallApi.loginByToken({ token })
    .then(authUser => authUser)
    .catch(error => error);

const getPermission = async (token) =>
  await CallApi.getPermission({ token })
    .then(authUser => authUser)
    .catch(error => error);

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  // let { intl } = this.props
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (!signInUser.code) {

      yield put(showAuthMessage(signInUser));
    } else {
      let data = signInUser.data;

      localStorage.setItem('x-token-key', data.token);
      const getpermission = yield call(getPermission, data.token)
      localStorage.setItem('permission', JSON.stringify(getpermission.data.permission));
      if (getpermission.data.permission && getpermission.data.permission.length > 0) {
        yield put(userSignInSuccess(data.token, data.profile));
      }
      else {
        yield put(showAuthMessage("Bạn không có quyền"));
      }

    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithToken() {
  let token = localStorage.getItem('x-token-key');
  try {
    const signInUser = yield call(signInUserWithTokenRequest, token);
    if (!signInUser.code) {
      localStorage.removeItem('x-token-key');
      yield put(loadAuthen(false));
    } else {
      let data = signInUser.data;
      localStorage.setItem('x-token-key', data.token);
      const getpermission = yield call(getPermission, token)
      localStorage.setItem('permission', JSON.stringify(getpermission.data.permission));
      if (getpermission.data.permission && getpermission.data.permission.length > 0) {
        yield put(userSignInByTokenSuccess(data.token, data.profile));
      }
      else {
        localStorage.removeItem('x-token-key');
        yield put(loadAuthen(false));
      }
    }
  } catch (error) {
    localStorage.removeItem('x-token-key');
    yield put(loadAuthen(false));
  }
}


function* signOut() {
  localStorage.removeItem('x-token-key');
  localStorage.removeItem('permission');
  yield put(userSignOutSuccess(signOutUser));
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signInUserByToken() {
  yield takeEvery(SIGNIN_USER_BY_TOKEN, signInUserWithToken);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
  fork(signInUserByToken),
  fork(signOutUser)]);
}
