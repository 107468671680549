import { FetchApi } from '../../axios';

//getListTouristDestination
function getListTouristDestination(data) {
    return FetchApi('/v1.0/tourist-destination', 'get', data)
}
function getAllTourisDestination(data) {
    return FetchApi('/v1.0/tourist-destination/list/all', 'get', data)
}
function getAllListTourisdestination(data) {
    return FetchApi('/v1.0/tourist-destination/list/get-list-all', 'get', data)
}
function deleteTouristDestination(data) {
    return FetchApi('/v1.0/tourist-destination', 'delete', data)
}
function createTouristDestination(data) {
    return FetchApi('/v1.0/tourist-destination', 'post', data)
}

function updateTouristDestination(data) {
    return FetchApi('/v1.0/tourist-destination', 'put', data)
}
function getTouristDestinationByID(data) {
    return FetchApi(`/v1.0/tourist-destination/${data}`, 'get')
}

//get getListTouristAttraction
function getAllListTouristAttraction(data) {
    return FetchApi('/v1.0/tourist_attraction/list/get-list-all', 'get', data)
}
function getListTouristAttraction(data) {
    return FetchApi('/v1.0/tourist_attraction', 'get', data)
}
function getAllTouristAttraction(data) {
    return FetchApi('/v1.0/tourist_attraction/list/all', 'get', data)
}
function createTouristAttraction(data) {
    return FetchApi('/v1.0/tourist_attraction', 'post', data)
}
function updateTouristAttraction(data) {
    return FetchApi('/v1.0/tourist_attraction', 'put', data)
}
function ActiveTouristAttraction(data) {
    return FetchApi('/v1.0/tourist_attraction/active/active_attraction', 'put', data)
}
function getTouristAttractionById(data) {
    return FetchApi(`/v1.0/tourist_attraction/${data}`, 'get')
}
function getNameOfTouristAttraction(data) {
    return FetchApi(`/v1.0/tourist_attraction/get-name/${data}`, 'get')
}
function deleteTouristAttraction(data) {
    return FetchApi('/v1.0/tourist_attraction', 'delete', data)
}

//get getListTouristAttraction
function getListScheduleSuggestTouristAttraction(data) {
    return FetchApi('/v1.0/schedule_suggest_tourist_attraction', 'get', data)
}
function createScheduleSuggestTouristAttraction(data) {
    return FetchApi('/v1.0/schedule_suggest_tourist_attraction', 'post', data)
}
function updateScheduleSuggestTouristAttraction(data) {
    return FetchApi('/v1.0/schedule_suggest_tourist_attraction', 'put', data)
}
function getScheduleSuggestTouristAttractionById(data) {
    return FetchApi(`/v1.0/schedule_suggest_tourist_attraction/${data}`, 'get')
}
function deleteListScheduleSuggestTouristAttraction(data) {
    return FetchApi('/v1.0/schedule_suggest_tourist_attraction', 'delete', data)
}



export default {
    getListTouristDestination,
    getAllListTouristAttraction,
    getNameOfTouristAttraction,
    getAllTouristAttraction,
    getAllTourisDestination,
    getAllListTourisdestination,
    deleteTouristDestination,
    createTouristDestination,
    updateTouristDestination,
    getTouristDestinationByID,
    getListTouristAttraction,
    createTouristAttraction,
    updateTouristAttraction,
    ActiveTouristAttraction,
    getTouristAttractionById,
    deleteTouristAttraction,
    getListScheduleSuggestTouristAttraction,
    deleteListScheduleSuggestTouristAttraction,
    createScheduleSuggestTouristAttraction,
    updateScheduleSuggestTouristAttraction,
    getScheduleSuggestTouristAttractionById
}