import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
const Place = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}/area-country`} component={asyncComponent(() => import('../../components/Place/AreaAndCountry'))} />
        <Route path={`${match.url}/region-province/:country_id`} component={asyncComponent(() => import('../../components/Place/RegionAndProvince'))} />
        <Route path={`${match.url}/district/:province_id/:country_id`} component={asyncComponent(() => import('../../components/Place/ListDistrict'))} />

        <Route path={`${match.url}/street/:district_id/:province_id/:country_id`} component={asyncComponent(() => import('../../components/Place/ListStreet'))} />

        {/* route chi tiet diem den */}
        <Route path={`${match.url}/active_attraction`} component={asyncComponent(() => import('../../components/Place/ListActiveTouristAttraction'))} />
        <Route path={`${match.url}/tourist-destination/:tourist_destination_id`} component={asyncComponent(() => import('../../components/Place/ListTouristAttraction'))} />
        <Route path={`${match.url}/tourist-destination`} component={asyncComponent(() => import('../../components/Place/ListTouristDestination'))} />
        <Route path={`${match.url}/tourist_attraction/suggest`} component={asyncComponent(() => import('../../components/Place/ListScheduleSuggestTouristAttraction'))} />
        <Route path={`${match.url}/tourist-attraction/:tourist_attraction_id`} component={asyncComponent(() => import('../../components/Ticket/ListTIcketOfTouristAttraction'))} />
        <Route path={`${match.url}/service/tourist_attraction/:tourist_attraction_id`} component={asyncComponent(() => import('../../components/Place/ListServiceAttraction'))} />
    </Switch>
)

export default Place;
