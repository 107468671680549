import { FetchApi } from '../../axios';


function getListTransaction(data) {
    return FetchApi('/v1.0/transaction', 'get', data)
}

function getAllTransaction(data) {
    return FetchApi('/v1.0/transaction/all', 'get', data)
}

export default {
    getListTransaction,
    getAllTransaction
}