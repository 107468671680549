import { FetchApi } from '../../axios';

function getListPartner(data) {
    return FetchApi('/v1.0/partner', 'get', data)
}
function getAllListPartner(data) {
    return FetchApi('/v1.0/partner/list/get-list-all', 'get', data)
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createPartner(data) {
    return FetchApi('/v1.0/partner', 'post', data);
}
function updatePartner(data) {
    return FetchApi('/v1.0/partner', 'put', data)
}
function getPartnerById(data) {
    return FetchApi(`/v1.0/partner/${data}`, 'get')
}
function deletePartner(data) {
    return FetchApi('/v1.0/partner', 'delete', data)

}

//Banner


export default {
    getListPartner,
    getAllListPartner,
    createPartner,
    updatePartner,
    getPartnerById,
    deletePartner,


}