import { FetchApi } from '../../axios';

function getListArea(data) {
    return FetchApi('/v1.0/areas', 'get', data)
}

function deleteArea(data) {
    return FetchApi('/v1.0/areas', 'delete', data)
}

function createArea(data) {
    return FetchApi('/v1.0/areas', 'post', data)
}

function updateArea(data) {
    return FetchApi('/v1.0/areas', 'put', data)
}

function getAreaByID(data) {
    return FetchApi(`/v1.0/areas/${data}`, 'get')
}

function getAreaActive(data) {
    return FetchApi(`/v1.0/areas/active`, 'get',data)
}
export default{
    getListArea,
    deleteArea,
    createArea,
    updateArea,
    getAreaByID,
    getAreaActive
}