import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
import { CheckPermission } from "util/CheckPermission";

const Move = ({ match }) => (
    <Switch>
        {CheckPermission("rep_customer") && <Route exact path={`${match.url}/request-customer`} component={asyncComponent(() => import('../../components/Customer/ListRequestCustomer'))} />}
    </Switch>
)

export default Move;
