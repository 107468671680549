


import { FetchApi } from '../../axios';


function getListRequestDepositCart(data) {
    return FetchApi('/v1.0/request_deposit_cart', 'get', data)
}
// function getAllListRequestDepositCart(data) {
//     return FetchApi('/v1.0/request_deposit_cart/list/get-list-all', 'get', data)
// }
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
// function createRequestDepositCart(data) {
//     return FetchApi('/v1.0/request_deposit_cart', 'post', data);
// }
function updateRequestDepositCart(data) {
    return FetchApi('/v1.0/request_deposit_cart', 'put', data)
}
function getRequestDepositCartById(data) {
    return FetchApi(`/v1.0/request_deposit_cart/${data}`, 'get')
}
// function getAllHistoryRefundRoseByUserId(data) {
//     return FetchApi(`/v1.0/refund_rose_request/get-by-user_id/get-list`, 'get', data)
// }
function deleteRequestDepositCart(data) {
    return FetchApi('/v1.0/request_deposit_cart', 'delete', data)
}

export default {
    getListRequestDepositCart,
    // getAllHistoryRefundRoseByUserId,
    updateRequestDepositCart,
    getRequestDepositCartById,
    deleteRequestDepositCart
}