import enLang from "./entries/en-US";
import viLang from "./entries/vi_VN";
import { addLocaleData } from "react-intl";

const AppLocale = {
  vi: viLang,
  en: enLang,
};
addLocaleData(AppLocale.vi.data);
addLocaleData(AppLocale.en.data);
export default AppLocale;
