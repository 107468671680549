import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Move = ({ match }) => (
    <Switch>
        {/* <Route exact path={`${match.url}/create`} component={asyncComponent(() => import('../../components/Restaurant/RestaurantCreate'))} />
        <Route exact path={`${match.url}/create/:restaurant_id`} component={asyncComponent(() => import('../../components/Restaurant/RestaurantCreate'))} /> */}
        <Route exact path={`${match.url}/category/list`} component={asyncComponent(() => import('../../components/Move/ListCategoryMove'))} />
        <Route exact path={`${match.url}/service/list`} component={asyncComponent(() => import('../../components/Move/ListMService'))} />
        <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/Move/ListMove'))} />
        <Route exact path={`${match.url}/:id`} component={asyncComponent(() => import('../../components/Move/ListServiceOfMove'))} />
        {/* <Route exact path={`${match.url}/:sub_id/menu`} component={asyncComponent(() => import('../../components/Restaurant/ListMenu'))} /> */}
        <Route exact path={`${match.url}/car-ticket/cheap`} component={asyncComponent(() => import('../../components/Move/ListMoveCheapCarTicket'))} />
        {/* <Route exact path={`${match.url}/:sub_id/menu/:menu_id`} component={asyncComponent(() => import('../../components/Restaurant/ListFoodOfMenu'))} />
        <Route exact path={`${match.url}/:sub_id/food`} component={asyncComponent(() => import('../../components/Restaurant/ListFood'))} /> */}
    </Switch>
)

export default Move;
