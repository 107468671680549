import { FetchApi } from '../../axios';

function getListPlaceAdvertis(data) {
    return FetchApi('/v1.0/placeadvertis', 'get', data)
}
function getAllListPlaceAdvertis(data) {
    return FetchApi('/v1.0/placeadvertis/list/get-list-all', 'get', data)
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createPlaceAdvertis(data) {
    return FetchApi('/v1.0/placeadvertis', 'post', data);
}
function updatePlaceAdvertis(data) {
    return FetchApi('/v1.0/placeadvertis', 'put', data)
}
function getPlaceAdvertisById(data) {
    return FetchApi(`/v1.0/placeadvertis/${data}`, 'get')
}
function deletePlaceAdvertis(data) {
    return FetchApi('/v1.0/placeadvertis', 'delete', data)

}

//Banner

function getListBanner(data) {
    return FetchApi('/v1.0/banner', 'get', data)
}
// function getAllListPlaceAdvertis(data) {
//     return FetchApi('/v1.0/banner/list/get-list-all', 'get', data)
// }

function createBanner(data) {
    return FetchApi('/v1.0/banner', 'post', data);
}
function updateBanner(data) {
    return FetchApi('/v1.0/banner', 'put', data)
}
function getBannerById(data) {
    return FetchApi(`/v1.0/banner/${data}`, 'get')
}
function deleteBanner(data) {
    return FetchApi('/v1.0/banner', 'delete', data)

}
export default {
    getListPlaceAdvertis,
    getAllListPlaceAdvertis,
    createPlaceAdvertis,
    updatePlaceAdvertis,
    getPlaceAdvertisById,
    deletePlaceAdvertis,
    getListBanner,
    createBanner,
    updateBanner,
    getBannerById,
    deleteBanner

}