import { FetchApi } from '../../axios';

function login(data) {
    return FetchApi('/v1.0/users/login', 'post', data)
}

function loginByToken(data) {
    return FetchApi('/v1.0/users/login-token', 'post', data)
}
function getPermission(data) {
    return FetchApi('/v1.0/users/get-permission', 'post', data)
}

function createUser(data) {
    return FetchApi('/v1.0/users', 'post', data)
}

function getListUser(data) {
    return FetchApi('/v1.0/users', 'get', data)
}
function getSpecialUser(data) {
    return FetchApi('/v1.0/users/get-list-special-user', 'get', data)
}
function updateSpecialUser(data) {
    return FetchApi('/v1.0/users/update_special_user', 'post', data)
}
function createSpecialUser(data) {
    return FetchApi('/v1.0/users/create_special_user', 'post', data)
}

function getAllUserByMonth(data) {
    return FetchApi('/v1.0/users/get-all-by-month', 'get', data)
}
function getUserById(data) {
    return FetchApi('/v1.0/users/get-info-user-by-id', 'get', data)
}
function getListPointHistory(data) {
    return FetchApi('/v1.0/users/get-list-point-history', 'get', data)
}
function updateStatusUser(data) {
    return FetchApi('/v1.0/users', 'put', data)
}
function changePassword(data) {
    return FetchApi('/v1.0/users/change-pass', 'put', data)
}
function updateUser(data) {
    return FetchApi('/v1.0/users/update-user', 'put', data)
}
function getCountUser() {
    return FetchApi('/v1.0/users/count_user', 'get')
}

export default {
    login,
    loginByToken,
    createUser,
    getListUser,
    getAllUserByMonth,
    updateStatusUser,
    getUserById,
    getListPointHistory,
    getPermission,
    getSpecialUser,
    updateSpecialUser,
    createSpecialUser,
    updateUser,
    changePassword,
    getCountUser
}