import { FetchApi } from '../../axios';

function getListHDirection(data) {
    return FetchApi('/v1.0/hdirection', 'get', data)
}

function deleteHDirection(data) {
    return FetchApi('/v1.0/hdirection', 'delete', data)
}

function createHDirection(data) {
    return FetchApi('/v1.0/hdirection', 'post', data)
}

function updateHDirection(data) {
    return FetchApi('/v1.0/hdirection', 'put', data)
}

function getHDirectionByID(data) {
    return FetchApi(`/v1.0/hdirection/${data}`, 'get')
}

export default {
    getListHDirection,
    deleteHDirection,
    createHDirection,
    updateHDirection,
    getHDirectionByID
}