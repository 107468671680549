import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";
const Place = ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}/create-or-update`} component={asyncComponent(() => import('../../components/Hotel/CreateAndUpdateHotel'))} />
        <Route exact path={`${match.url}/service/category`} component={asyncComponent(() => import('../../components/Hotel/Service/CategoryService'))} />
        <Route exact path={`${match.url}/service/:category_service_id`} component={asyncComponent(() => import('../../components/Hotel/Service/ListHService'))} />
        <Route exact path={`${match.url}/service/room/category`} component={asyncComponent(() => import('../../components/Hotel/ServiceRoom/CategoryServiceRoom'))} />
        <Route exact path={`${match.url}/service/room/:category_service_room_id`} component={asyncComponent(() => import('../../components/Hotel/ServiceRoom/ListHServiceRoom'))} />
        <Route exact path={`${match.url}/room/category`} component={asyncComponent(() => import('../../components/Hotel/Room/CategoryRoom'))} />
        <Route exact path={`${match.url}/room/:category_room_id`} component={asyncComponent(() => import('../../components/Hotel/Room/ListHRoom'))} />
        <Route exact path={`${match.url}/bed/category`} component={asyncComponent(() => import('../../components/Hotel/Bed/CategoryBed'))} />
        <Route exact path={`${match.url}/direction`} component={asyncComponent(() => import('../../components/Hotel/Direction/ListHDirection'))} />
    </Switch>
)

export default Place;
