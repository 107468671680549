import React from "react";
import { Route, Switch } from "react-router-dom";
import { CheckPermission } from "util/CheckPermission";
import asyncComponent from "util/asyncComponent";

const Tour = ({ match }) => (
    <Switch>
        {CheckPermission("create_tour") && <Route exact path={`${match.url}/create`} component={asyncComponent(() => import('../../components/Tour/TourCreate'))} />}
        {CheckPermission("create_tour") && <Route exact path={`${match.url}/create/:tour_id`} component={asyncComponent(() => import('../../components/Tour/TourCreate'))} />}
        <Route exact path={`${match.url}/list`} component={asyncComponent(() => import('../../components/Tour/TourList'))} />
        {/* {CheckPermission("create_tour") && CheckPermission("update_tour") && <Route exact path={`${match.url}/:sub_id/menu`} component={asyncComponent(() => import('../../components/Tour/ListMenu'))} />} */}
        <Route exact path={`${match.url}/category/list`} component={asyncComponent(() => import('../../components/Tour/ListCategoryTour'))} />
        {/* {CheckPermission("create_tour") && CheckPermission("update_tour") && <Route exact path={`${match.url}/:sub_id/menu/:menu_id`} component={asyncComponent(() => import('../../components/Tour/ListFoodOfMenu'))} />}
        {CheckPermission("create_tour") && CheckPermission("update_tour") && <Route exact path={`${match.url}/:sub_id/food`} component={asyncComponent(() => import('../../components/Tour/ListFood'))} />} */}
    </Switch>
)

export default Tour;
