import { FetchApi } from '../../axios';

function getListHCategoryRoom(data) {
    return FetchApi('/v1.0/hcategory_room', 'get', data)
}

function deleteHCategoryRoom(data) {
    return FetchApi('/v1.0/hcategory_room', 'delete', data)
}

function createHCategoryRoom(data) {
    return FetchApi('/v1.0/hcategory_room', 'post', data)
}

function updateHCategoryRoom(data) {
    return FetchApi('/v1.0/hcategory_room', 'put', data)
}

function getHCategoryRoomByID(data) {
    return FetchApi(`/v1.0/hcategory_room/${data}`, 'get')
}

export default {
    getListHCategoryRoom,
    deleteHCategoryRoom,
    createHCategoryRoom,
    updateHCategoryRoom,
    getHCategoryRoomByID
}