import { FetchApi } from '../../axios';

function getListDistrict(data) {
    return FetchApi('/v1.0/district', 'get', data)
}

function createDistrict(data) {
    return FetchApi('/v1.0/district', 'post', data)
}

function updateDistrict(data) {
    return FetchApi('/v1.0/district', 'put', data)
}

function getDistrictByID(data) {
    return FetchApi(`/v1.0/district/${data.id}`, 'get', data)
}

function deleteDistrict(data) {
    return FetchApi('/v1.0/district', 'delete', data)
}

export default {
    getListDistrict,
    deleteDistrict,
    createDistrict,
    updateDistrict,
    getDistrictByID
}