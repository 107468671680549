import { FetchApi } from '../../axios';


function getListRole(data) {
    return FetchApi('/v1.0/role', 'get', data)
}
function getAllListRole(data) {
    return FetchApi('/v1.0/role/list/get-list-all', 'get', data)
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function updateRolePermission(data) {
    return FetchApi('/v1.0/role/update-role-permission', 'put', data)
}
function createRole(data) {
    return FetchApi('/v1.0/role', 'post', data);
}
function updateRole(data) {
    return FetchApi('/v1.0/role', 'put', data)
}
function getRoleById(data) {
    return FetchApi(`/v1.0/role/${data}`, 'get')
}
function deleteRole(data) {
    return FetchApi('/v1.0/role', 'delete', data)
}
//user role

function getListUserRole(data) {
    return FetchApi('/v1.0/user_role', 'get', data)
}
function getListAdmin(data) {
    return FetchApi('/v1.0/user_role/admin/list', 'get', data)
}
function getAllListUserRole(data) {
    return FetchApi('/v1.0/user_role/list/get-list-all', 'get', data)
}
// function getFoodOfMenu(data) {
//     return FetchApi('/v1.0/rmenu/list/get-food-of-menu', 'get', data)
// }
function createUserRole(data) {
    return FetchApi('/v1.0/user_role', 'post', data);
}
function createUserAndRole(data) {
    return FetchApi('/v1.0/user_role/create/user-and-role', 'post', data);
}
function updateUserRole(data) {
    return FetchApi('/v1.0/user_role', 'put', data)
}
function updateOwner(data) {
    return FetchApi('/v1.0/user_role/update-owner', 'put', data)
}

function getUserRoleById(data) {
    return FetchApi(`/v1.0/user_role/${data}`, 'get')
}
function deleteUserRole(data) {
    return FetchApi('/v1.0/user_role', 'delete', data)
}

export default {
    getListRole,
    getAllListRole,
    createRole,
    updateRole,
    getRoleById,
    deleteRole,
    getListUserRole,
    getListAdmin,
    getAllListUserRole,
    createUserRole,
    createUserAndRole,
    updateUserRole,
    updateOwner,
    updateRolePermission,
    getUserRoleById,
    deleteUserRole
}